import { Button, IconButton } from "@components/library";
import { COLORS, FONTS } from "@constants";
import { connect } from "react-redux";
import { Modal } from "semantic-ui-react";
import styled from "styled-components";

const SubscriptionEndingModal = ({
  isOpen,
  closeModal,
  daysRemaining,
  currentUser,
  superAdmin,
}) => {
  const handleButtonClick = () => {
    window.open(
      currentUser.profile_info.is_super_admin ? "/contact" : `mailto:${superAdmin.email}`,
      "_blank"
    );

    closeModal();
  };

  let titleText = `Only ${daysRemaining} day${
    daysRemaining === 1 ? "" : "s"
  } left on your subscription.`;
  if (daysRemaining === 0) {
    titleText = "Your subscription expires today.";
  } else if (daysRemaining < 0) {
    titleText = `${currentUser.role === 1 ? "Your" : "The sponsor's"} subscription has expired.`;
  }

  return (
    <Container open={isOpen} onClose={closeModal}>
      <CloseButton>
        <IconButton
          iconName="Close"
          variant="fourth"
          onClick={closeModal}
          tooltipPosition="bottom"
        />
      </CloseButton>
      <Title>{titleText}</Title>
      {currentUser.role === 1 ? (
        <div>
          {daysRemaining >= 0
            ? "Your Halo subscription is expiring soon! Renew your subscription to keep accessing "
            : "Renew to access "}
          all Halo features, including viewing proposals, evaluating proposals, and contacting
          scientists.
          {!currentUser.profile_info.is_super_admin
            ? ` Your company admin is ${superAdmin.first_name} ${superAdmin.last_name}.`
            : ""}
        </div>
      ) : (
        <div>
          You can no longer view proposals submitted to this opportunity. If you have questions,
          contact the sponsor's admin.
        </div>
      )}
      <Button type="button" onClick={handleButtonClick}>
        {currentUser.profile_info.is_super_admin
          ? "Contact Us"
          : `Contact ${currentUser.role === 1 ? superAdmin.first_name : "admin"}`}
      </Button>
    </Container>
  );
};

const mapState = (state) => ({
  currentUser: state.profiles.currentUser,
  superAdmin: state.defaultReducer.currentCompany.super_admin,
});

export default connect(mapState)(SubscriptionEndingModal);

const Container = styled(Modal)`
  display: flex;
  flex-direction: column;
  position: relative;
  color: ${COLORS.BLACK};
  padding: 40px;
  && {
    ${FONTS.REGULAR_1};
    width: 90%;
    max-width: 700px;
  }

  & > button {
    margin-top: 24px;
  }
`;
const CloseButton = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  height: 25px;
`;
const Title = styled.div`
  ${FONTS.HEADING_4_SEMIBOLD};
  margin-bottom: 20px;
`;
