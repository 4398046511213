import MasqueradeBar from "@components/MasqueradeBar";
import Messenger from "@components/Messenger/Messenger";
import MobileRestricted from "@components/MobileRestricted";
import PrivateSponsorRoute from "@components/PrivateSponsorRoute";
import SubscriptionWarning from "@components/SubscriptionWarning";
import TaxonomyUpdateModal from "@components/TaxonomyUpdateModal";
import { Toaster } from "@components/Toast";
import toast from "@components/Toast/Toast";
import SSOSponsorOnboardingModal from "@components/modals/SSOSponsorOnboardingModal";
import UniversityExpertiseUpdateModal from "@components/modals/UniversityExpertiseUpdateModal";
import { COLORS, FEATURE_FLAGS, JS_FONTS, WINDOW_DIMENSIONS } from "@constants";
import { fetchSubscription as fetchSubscriptionAction } from "@redux/actions/companyActions";
import { clearMessengerState as clearMessengerStateAction } from "@redux/actions/messengerActions";
import { signOut } from "@requests/users";
import SubmitProposalRedirect from "@routes/SubmitProposalRedirect";
import { profile_page_path } from "@routes/routes";
import { marketplacePath } from "@routes/utils";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { RequestableType } from "@tsTypes/requests";
import { UserRole } from "@tsTypes/users";
import ScrollToTop from "@utils/ScrollToTop";
import { identify, lazyWithRetry, page, setPageTitle } from "@utils/appUtils";
import { getJustLoggedIn, setPrevUrlCookie } from "@utils/authUtils";
import { hasInvalidUniversityExpertise } from "@utils/userUtils";
import { StyleSheet, css } from "aphrodite";
import axios from "axios";
import moment from "moment";
import { PureComponent, Suspense } from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { Slide, ToastContainer } from "react-toastify";
import { Loader } from "semantic-ui-react";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import PrivateRoute from "./components/PrivateRoute";
import { USER_ROLES } from "./constants/users";
import {
  checkUnread as checkUnreadAction,
  fetchCurrentUser as fetchCurrentUserAction,
  setCurrentUser as setCurrentUserAction,
} from "./redux/actions/profileActions";
import { updateWindowDimension as updateWindowDimensionAction } from "./redux/actions/windowDimensionsActions";
import NotificationCampaignRoutes from "./routes/notification_campaign_routes";
import OpportunityRoutes from "./routes/opportunity_routes";
import RequestForExpertRoutes from "./routes/request_for_expert_routes";
import RfpRoutes from "./routes/rfp_routes";
import ErrorBoundary from "./views/ErrorBoundary";
import OnboardingContainer from "./views/Onboarding/OnboardingContainer";
import Forbidden403 from "./views/StatusPages/403";
import NotFound404 from "./views/StatusPages/404";
import { SignUpPage } from "./views/WhiteLabel/SignUp";
const RfpPage = lazyWithRetry(() => import("./views/RFPPage"));
const RequestForStartupPage = lazyWithRetry(() => import("./views/RequestForStartupPage"));
const JobPage = lazyWithRetry(() => import("./views/JobPage"));
const CompanyPage = lazyWithRetry(() => import("./views/CompanyPage"));
const ProfilePage = lazyWithRetry(() => import("./views/ProfilePage"));
const ReviewerDashboard = lazyWithRetry(() => import("./views/ReviewerDashboard"));
const ProposalDashboard = lazyWithRetry(() => import("./views/ProposalDashboard"));
const ProposalReviewSystem = lazyWithRetry(() => import("./views/ProposalReviewSystem"));
const Account = lazyWithRetry(() => import("./views/Account"));
const RfpOpenCallProposalFormNew = lazyWithRetry(
  () => import("./views/RfpOpenCallProposalFormNew")
);
const RequestForStartupProposalFormNew = lazyWithRetry(
  () => import("./views/RequestForStartupProposalFormNew")
);
const RequestForExpertProposalForm = lazyWithRetry(
  () => import("./views/RequestForExpertProposalForm")
);
const ResearchPage = lazyWithRetry(() => import("./views/ResearchPage"));
const Marketplace = lazyWithRetry(() => import("./views/Marketplace"));
const TermsPage = lazyWithRetry(() => import("./views/TermsPage"));
const PrivacyPage = lazyWithRetry(() => import("./views/PrivacyPage"));
const ContactPage = lazyWithRetry(() => import("./views/ContactPage"));
const SponsorTeam = lazyWithRetry(() => import("./views/SponsorTeam"));
const UniversityDashboardPage = lazyWithRetry(() => import("./views/UniversityDashboardPage"));
const ForgotPassword = lazyWithRetry(() => import("./views/ForgotPassword"));
const ChangePassword = lazyWithRetry(() => import("./views/ChangePassword"));
const ProposalPage = lazyWithRetry(() => import("./views/ProposalPage"));
const ScientistProposalLibrary = lazyWithRetry(() => import("./views/ScientistProposalLibrary"));
const Careers = lazyWithRetry(() => import("./views/Careers"));
const Inbox = lazyWithRetry(() => import("./views/Inbox"));
const ProposalLibrary = lazyWithRetry(() => import("./views/ProposalLibrary"));
const ScientistNetwork = lazyWithRetry(() => import("./views/ScientistNetwork"));
const AboutUs = lazyWithRetry(() => import("./views/AboutUs"));
const LoginPage = lazyWithRetry(() => import("./views/LoginPage"));
const SignUp = lazyWithRetry(() => import("./views/SignUp/SignUp"));
const ScientistNetworkSignUp = lazyWithRetry(() => import("./views/ScientistNetworkSignUp"));
const RecommendedRfpSignUp = lazyWithRetry(() => import("./views/RecommendedRfpSignUp"));
const WhiteLabelPlayground = lazyWithRetry(() => import("./views/WhiteLabel/Playground"));
const UniversityProposalLibrary = lazyWithRetry(() => import("./views/UniversityProposalLibrary"));
const UniversityTeam = lazyWithRetry(() => import("./views/UniversityTeam"));
const FeatureRequestSplashPage = lazyWithRetry(() => import("./views/FeatureRequestSplashPage"));

const { TABLET_MEDIA_QUERY } = WINDOW_DIMENSIONS;

export enum RequestType {
  REQUEST_FOR_PROPOSAL = "request_for_proposal",
  OPEN_CALL = "open_call",
  REQUEST_FOR_STARTUP = "request_for_startup",
  REQUEST_FOR_EXPERT = "request_for_experts",
}

export const REQUESTABLE_TO_REQUEST_TYPE = {
  [RequestableType.RFP]: RequestType.REQUEST_FOR_PROPOSAL,
  [RequestableType.OPEN_CALL]: RequestType.OPEN_CALL,
  [RequestableType.REQUEST_FOR_STARTUP]: RequestType.REQUEST_FOR_STARTUP,
  [RequestableType.REQUEST_FOR_EXPERT]: RequestType.REQUEST_FOR_EXPERT,
};

// TODO: (overload119) Add correct typing.
type Props = any;
interface State {
  isTaxonomyUpdateModalOpen: boolean;
  isSSOSponsorOnboardingModalOpen: boolean;
  isUniversityExpertiseUpdateModalOpen: boolean;
  isCurrentUserLoading: boolean;
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const TIMEOUT_IN_MS = 900000; // 15 minutes
let sessionTimer: null | number = null;

const isPageRequiringReload = (url: string): boolean => {
  return url.startsWith("/scientists");
};

class App extends PureComponent<Props, State> {
  listener: (() => void) | null;

  constructor(props) {
    super(props);

    this.listener = null;
    this.state = {
      isTaxonomyUpdateModalOpen: false,
      isSSOSponsorOnboardingModalOpen: false,
      isUniversityExpertiseUpdateModalOpen: false,
      isCurrentUserLoading: true,
    };

    axios.defaults.headers.common.Accept = "application/json";
    axios.defaults.headers.common["Content-Type"] = "application/json";
  }

  async componentDidMount() {
    const {
      updateWindowDimension,
      fetchCurrentUser,
      checkUnread,
      clearMessengerState,
      fetchSubscription,
      history,
    } = this.props;

    this.listener = history.listen((location, action: "POP" | "PUSH") => {
      if (action === "POP" && isPageRequiringReload(location.pathname)) {
        window.location.reload();
      }
    });

    checkUnread();
    page();

    // Listen for window resizing, and update `windowDimension` in Redux
    window.addEventListener("resize", updateWindowDimension);

    if (window.outerWidth > 0 && window.outerHeight > 0) {
      // Sometimes window hasn't fully loaded yet so we shouldn't set to 0 in those
      // cases
      updateWindowDimension();
    }

    const user = await fetchCurrentUser(true);

    if (!user.id) {
      clearMessengerState();
    } else {
      // Fetch subscription info if user is a sponsor
      if (user.role === USER_ROLES.sponsor) {
        fetchSubscription(user.profile_info.company_identifier);
      }

      const wasUserCreatedMoreThan1DayAgo = moment().diff(moment(user.created_at), "days") > 1;
      const isSponsorUserMissingTitleAndRole =
        user?.role === UserRole.SPONSOR &&
        user.profile_info &&
        !user.profile_info.title &&
        !user.profile_info.role;

      this.setState({
        isTaxonomyUpdateModalOpen:
          !location.pathname.startsWith("/onboarding") &&
          !location.pathname.startsWith("/contact") &&
          user?.role !== UserRole.UNIVERSITY_ADMIN &&
          user?.has_updated_taxonomy === false,
        isSSOSponsorOnboardingModalOpen:
          !location.pathname.startsWith("/onboarding") &&
          !location.pathname.startsWith("/network") &&
          !location.pathname.startsWith("/contact") &&
          wasUserCreatedMoreThan1DayAgo &&
          isSponsorUserMissingTitleAndRole,
        isUniversityExpertiseUpdateModalOpen:
          !FEATURE_FLAGS.UNIVERSITY_ADMIN_DISCIPLINES &&
          !location.pathname.startsWith("/onboarding") &&
          !location.pathname.startsWith("/contact") &&
          user?.role === UserRole.UNIVERSITY_ADMIN &&
          (!user.profile_info?.university_expertise ||
            hasInvalidUniversityExpertise(user.profile_info.university_expertise)),
      });
    }

    this.setState({
      isCurrentUserLoading: false,
    });

    // Move webflow content into the webflow container in order to place between navbar and footer
    const webflowContent = document.getElementById("webflow-content");
    if (webflowContent) {
      if (
        this.props.location.pathname === "/" ||
        this.props.location.pathname.startsWith("/scientists")
      ) {
        webflowContent.style.display = "block";
        const webflowContainer = document.getElementById("webflow-container");
        webflowContainer?.appendChild(webflowContent);
      } else {
        webflowContent.style.display = "none";
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.windowWidth === this.props.windowWidth) {
      this.props.checkUnread();
    }

    if (this.props?.currentUser) {
      identify(this.props.currentUser);
      if (!prevProps?.currentUser?.id && getJustLoggedIn()) {
        // Show success toast if user just logged in on the white label
        if (location.pathname.startsWith("/partner")) {
          toast.success("Signed-in successfully");
        }
      }

      // NOTE: (overload119) An idle page will
      // auto-logout if this feature is enabled.
      if (this.props.currentUser?.id && FEATURE_FLAGS.SESSION_TIMEOUT) {
        // Prevent more than 1 instance of the timeout.
        if (sessionTimer !== null) {
          clearTimeout(sessionTimer);
        }
        sessionTimer = setTimeout(async () => {
          await signOut();
          toast.error("You have been signed out due to inactivity.");
          clearTimeout(sessionTimer as number);
          sessionTimer = null;
        }, TIMEOUT_IN_MS) as unknown as number;
      }
    }

    if (this.props.location !== prevProps.location) {
      page();
    }
  }

  componentWillUnmount() {
    const { updateWindowDimension } = this.props;
    window.removeEventListener("resize", updateWindowDimension);
    this.listener?.();
  }

  render() {
    const { location, currentUser } = this.props;

    setPageTitle(location.pathname);

    const isWhiteLabel = location.pathname.startsWith("/partner");

    const isWebflow = location.pathname === "/" || location.pathname.startsWith("/scientists");

    const noFooter =
      isWhiteLabel ||
      location.pathname.includes("/submit_proposal") ||
      location.pathname.startsWith("/opportunities") ||
      location.pathname.startsWith("/university_dashboard") ||
      location.pathname.startsWith("/submit/") ||
      location.pathname.startsWith("/inbox") ||
      location.pathname.startsWith("/w/") ||
      location.pathname.startsWith("/login") ||
      location.pathname.startsWith("/get-started") ||
      location.pathname.startsWith("/preview-rfp/") ||
      location.pathname.startsWith("/onboarding") ||
      location.pathname.includes("/signup") ||
      location.pathname.startsWith("/review_system");

    const noChat =
      !currentUser.id ||
      isWhiteLabel ||
      location.pathname.includes("/submit_proposal") ||
      location.pathname.startsWith("/submit/") ||
      location.pathname.startsWith("/w/") ||
      location.pathname.startsWith("/preview-rfp/") ||
      location.pathname.startsWith("/onboarding") ||
      location.pathname.includes("/signup");

    // Track original referrer id
    if (localStorage && !localStorage.getItem("original_referrer_id")) {
      const urlParams = new URLSearchParams(window.location.search);
      const referrer_id = urlParams.get("referrer_id");

      if (referrer_id) {
        localStorage.setItem("original_referrer_id", referrer_id);
      }
    }

    // HACK: Temporary fix for unsubscribe page not working.
    // Remove this later and rethink this statement.
    if (
      (!location.pathname.startsWith("/contact") &&
        !location.pathname.startsWith("/unsubscribe") &&
        !gon.current_user_id &&
        currentUser.id) ||
      this.state.isCurrentUserLoading
    ) {
      return <Loader style={{ marginTop: "30vh" }} active inline="centered" />;
    }

    let homepage = marketplacePath();
    if (currentUser.role === USER_ROLES.sponsor) {
      if (currentUser.profile_info.commenter) homepage = "/reviewer_dashboard/proposals";
      else homepage = "/reviewer_dashboard";
    }
    if (currentUser.role === USER_ROLES.university_admin) homepage = "/university_dashboard/team";

    const whitelabel = location.pathname.startsWith("/w/");
    const proposalDashboard = location.pathname.startsWith("/reviewer_dashboard/proposals");

    return (
      <QueryClientProvider client={queryClient}>
        {gon.user_masquerade && <MasqueradeBar />}
        <ErrorBoundary>
          <div className={css(styles.Marketplace_app, whitelabel ? styles.Marketplace_app_wl : "")}>
            <div
              data-test-profile-id={currentUser.profile_id}
              data-testid="marketplace-container-main"
              className={css(
                isWebflow ? "" : styles.Marketplace_mainContainer,
                whitelabel ? styles.Marketplace_mainContainer_wl : "",
                noFooter ? styles.Marketplace_noFooter : "",
                proposalDashboard ? styles.Marketplace_mobileContainer : ""
              )}
            >
              {!noChat && <Messenger />}
              <Navbar />
              {currentUser.role === UserRole.SPONSOR && <SubscriptionWarning />}
              <ScrollToTop>
                <Suspense
                  fallback={
                    <Loader
                      style={{
                        marginTop: "200px",
                        height: "500px",
                        alignItems: "center",
                        justifyContent: "center",
                        display: "flex",
                      }}
                      active
                      inline="centered"
                    />
                  }
                >
                  {this.state.isTaxonomyUpdateModalOpen && (
                    <TaxonomyUpdateModal
                      isOpen={this.state.isTaxonomyUpdateModalOpen}
                      onClose={() => this.setState({ isTaxonomyUpdateModalOpen: false })}
                    />
                  )}
                  {this.state.isSSOSponsorOnboardingModalOpen && (
                    <SSOSponsorOnboardingModal
                      isOpen={this.state.isSSOSponsorOnboardingModalOpen}
                      onClose={() => this.setState({ isSSOSponsorOnboardingModalOpen: false })}
                    />
                  )}
                  {this.state.isUniversityExpertiseUpdateModalOpen && (
                    <UniversityExpertiseUpdateModal
                      isOpen={this.state.isUniversityExpertiseUpdateModalOpen}
                      onClose={() => this.setState({ isUniversityExpertiseUpdateModalOpen: false })}
                    />
                  )}
                  <Switch>
                    {currentUser.id && (
                      <Route exact path="/">
                        <Redirect to={homepage} />
                      </Route>
                    )}
                    <Route exact path="/about" component={AboutUs} />
                    <Route exact path="/home" component={Marketplace} />
                    <PrivateRoute exact path="/my_proposals" component={ScientistProposalLibrary} />
                    <Route exact path="/login" component={LoginPage} />
                    <Redirect from="/register" to="/get-started" />
                    <Route exact path="/get-started/network" component={ScientistNetworkSignUp} />
                    {currentUser.id ? (
                      <Route exact path="/get-started/keywords">
                        <Redirect to={profile_page_path(currentUser.profile_id, { keywords: 1 })} />
                      </Route>
                    ) : (
                      <Route
                        exact
                        path="/get-started/keywords"
                        render={() => {
                          setPrevUrlCookie("/get-started/keywords");
                          return <Redirect to="/login" />;
                        }}
                      />
                    )}
                    <Route
                      exact
                      path="/get-started/opportunities"
                      component={RecommendedRfpSignUp}
                    />
                    <Route exact path="/get-started" component={SignUp} />
                    {/* Regular onboarding */}
                    <PrivateRoute exact path="/onboarding" component={OnboardingContainer} />
                    {/* White label onboarding */}
                    <Route
                      exact
                      path="/partner/:company_identifier/onboarding"
                      component={OnboardingContainer}
                    />
                    <Route exact path="/submit/:request_type/:slug">
                      {({ match }) => {
                        const { request_type } = match.params;
                        switch (request_type) {
                          case RequestType.REQUEST_FOR_PROPOSAL:
                          case RequestType.OPEN_CALL:
                            return <RfpOpenCallProposalFormNew />;
                          case RequestType.REQUEST_FOR_STARTUP:
                            return <RequestForStartupProposalFormNew />;
                          case RequestType.REQUEST_FOR_EXPERT:
                            return <RequestForExpertProposalForm />;
                          default:
                            console.error("Unexpected request_type: %s.", request_type);
                        }
                      }}
                    </Route>
                    <Route exact path="/submit_proposal/:rfp_identifier">
                      <SubmitProposalRedirect />
                    </Route>
                    <Route exact path="/company/:identifier" component={CompanyPage} />
                    <Route exact path="/profile/:id" component={ProfilePage} />
                    <PrivateSponsorRoute
                      exact
                      path="/reviewer_dashboard"
                      component={ReviewerDashboard}
                    />
                    {FEATURE_FLAGS.PROPOSAL_REVIEW_SYSTEM_V2 ? (
                      <PrivateRoute
                        exact
                        path="/reviewer_dashboard/proposals"
                        render={({ location: _location }) => (
                          <Redirect to={{ ..._location, pathname: "/review_system" }} />
                        )}
                      />
                    ) : (
                      <PrivateRoute
                        exact
                        path="/reviewer_dashboard/proposals"
                        component={ProposalDashboard}
                      />
                    )}
                    {FEATURE_FLAGS.PROPOSAL_REVIEW_SYSTEM_V2 && (
                      <PrivateRoute path="/review_system" exact component={ProposalReviewSystem} />
                    )}
                    <Redirect path="/expert_dashboard" to="/review_system" />
                    <PrivateRoute
                      exact
                      path="/reviewer_dashboard/teams"
                      render={() => (
                        <MobileRestricted breakpoint="500px">
                          <SponsorTeam />
                        </MobileRestricted>
                      )}
                    />
                    <PrivateRoute
                      exact
                      path="/university_dashboard/proposals"
                      component={UniversityProposalLibrary}
                    />
                    <PrivateRoute
                      exact
                      path="/university_dashboard/team"
                      component={UniversityTeam}
                    />
                    <PrivateRoute
                      exact
                      path="/university_dashboard/teams"
                      component={UniversityDashboardPage}
                    />
                    <PrivateRoute exact path="/account" component={Account} />
                    {FEATURE_FLAGS.REQUEST_FOR_STARTUPS && (
                      <Route
                        exact
                        path="/startup_program/:slug"
                        component={RequestForStartupPage}
                      />
                    )}
                    <Route
                      exact
                      path="/company/:company_identifier/job/:identifier"
                      component={JobPage}
                    />
                    {/* NOTE: (justinpchang) Consider refactoring route once more request types are added */}
                    <Route exact path="/proposal/:slug" component={ProposalPage} />
                    <Route exact path="/startup_program_proposal/:slug">
                      <ProposalPage requestableType={RequestableType.REQUEST_FOR_STARTUP} />
                    </Route>
                    <PrivateRoute exact path="/proposal_library" component={ProposalLibrary} />
                    {RfpRoutes}
                    {RequestForExpertRoutes}
                    {NotificationCampaignRoutes}
                    {OpportunityRoutes}
                    <Route exact path="/network" component={ScientistNetwork} />
                    <Route exact path="/research/:rfp_program" component={ResearchPage} />
                    <Route exact path="/terms" component={TermsPage} />
                    <Route exact path="/privacy" component={PrivacyPage} />
                    <Route exact path="/contact" component={ContactPage} />
                    <PrivateRoute exact path="/inbox" component={Inbox} />
                    <Route exact path="/forgot_password" component={ForgotPassword} />
                    <Route exact path="/change_password" component={ChangePassword} />
                    <Route exact path="/careers" component={Careers} />
                    <Route
                      exact
                      path="/partner/:company_identifier/playground"
                      component={WhiteLabelPlayground}
                    />
                    <Route
                      exact
                      path="/partner/:company_identifier/sign_up"
                      component={SignUpPage}
                    />
                    <Route
                      exact
                      path="/partner/:company_identifier/:identifier"
                      component={RfpPage}
                    />
                    <Route exact path="/partner/submit/:request_type/:slug">
                      {({ match }) => {
                        const { request_type } = match.params;
                        if (request_type === RequestType.REQUEST_FOR_PROPOSAL)
                          return <RfpOpenCallProposalFormNew />;
                        return <Redirect to={homepage} />;
                      }}
                    </Route>
                    <Route
                      path="/partner/:company_identifier/:rfp_identifier/submit"
                      render={({ match, location: { search } }) => (
                        <Redirect
                          to={`/partner/submit/request_for_proposal/${match.params.rfp_identifier}${search}`}
                        />
                      )}
                    />
                    <Route exact path="/sponsors">
                      <Redirect to="/marketplace/partners" />
                    </Route>
                    <Route path={"/marketplace"} component={Marketplace} />
                    <Route exact path="/feature_requests" component={FeatureRequestSplashPage} />
                    {/*
                      We render nothing on / since the Webflow layout is active on this page.
                      For unhandled routes we use window.location.href which properly refreshes the assets
                      and loads the Webflow layout. Otherwise it'd be an empty screen.
                    */}
                    <Route exact path="/" />
                    <Route exact path="/scientists" />
                    <Route exact path="/403" component={Forbidden403} />
                    <Route path="*" component={NotFound404} />
                  </Switch>
                </Suspense>
              </ScrollToTop>
            </div>
          </div>
          {isWebflow && <div id="webflow-container" />}
          <ToastContainer
            className={css(styles.toast_container)}
            toastClassName={css(styles.toast_inner)}
            bodyClassname={css(styles.toast_body)}
            progressClassName={css(styles.toast_progress)}
            transition={Slide}
            position="bottom-left"
            autoClose={5000}
            enableMultiContainer
          />
          <Toaster />
          {!noFooter && <Footer />}
        </ErrorBoundary>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.profiles.currentUser,
  windowWidth: state.window.width,
});

const mapDispatchToProps = {
  updateWindowDimension: updateWindowDimensionAction,
  fetchCurrentUser: fetchCurrentUserAction,
  setCurrentUser: setCurrentUserAction,
  checkUnread: checkUnreadAction,
  clearMessengerState: clearMessengerStateAction,
  fetchSubscription: fetchSubscriptionAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

const styles = StyleSheet.create({
  Marketplace_app: {
    position: "relative",
    display: "grid",
    height: "fit-content",
    gridTemplateColumns: "100%",
  },
  Marketplace_app_wl: {
    minHeight: "initial",
  },
  Marketplace_mainContainer: {
    display: "inline-block",
    position: "relative",
    minHeight: "100vh",
    marginBottom: "40px",
  },
  Marketplace_mobileContainer: {
    [TABLET_MEDIA_QUERY]: {
      ":nth-child(1n) > .ui.container": {
        marginLeft: "unset",
        marginRight: "unset",
        paddingLeft: "unset",
        paddingRight: "unset",
      },
    },
  },
  Marketplace_mainContainer_wl: {
    minHeight: "90vh",
  },
  Marketplace_noFooter: {
    marginBottom: "0px",
  },
  toast_body: {
    color: COLORS.WHITE,
    marginRight: "10px",
    ...JS_FONTS.MEDIUM_3,
  },
  toast_progress: {
    background: COLORS.HALO_BLUE,
  },
  toast_inner: {
    padding: "15px",
    paddingBottom: "20px",
    borderRadius: "4px",
  },
  toast_container: {
    zIndex: 1000000,
  },
  toast_close: {
    position: "initial",
  },
});
