import { Tag } from "@components/library";
import { FONTS } from "@constants";
import { RfpPageRequest } from "@tsTypes/index";
import styled from "styled-components";

interface Props {
  request: RfpPageRequest;
}

const RfpKeywordsContainer = ({ request }: Props) => {
  return (
    <Container>
      {request.disciplines.length > 0 && (
        <div>
          <Heading>Disciplines</Heading>
          {request.disciplines.map((area) => (
            <Tag key={`d-${area}`} content={area} size="sm" theme="neutral" />
          ))}
        </div>
      )}
      {request.areas_of_expertise.length > 0 && (
        <div>
          <Heading>Areas of Expertise</Heading>
          {request.areas_of_expertise.map((area) => (
            <Tag key={`aoe-${area}`} content={area} size="sm" theme="neutral" />
          ))}
        </div>
      )}
    </Container>
  );
};

export default RfpKeywordsContainer;

const Container = styled.div`
  margin-bottom: 48px;

  & > div {
    margin-top: 15px;
  }

  &:first-child {
    margin-top: 0;
  }
`;

const Heading = styled.div`
  ${FONTS.MEDIUM_1};
`;
