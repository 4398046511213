import { ReviewerDashboardRequest, RfpPageResponse, RfpsShowResponse } from "@tsTypes/index";
import { RfpPartnershipModel } from "@tsTypes/rfps";
import { stringArrayToBulletText } from "@utils/textUtils";
import { RfpSubmitData } from "src/views/RfpRequestForm/RfpRequestForm";
import request from "./request";

export const getRfpForRfpPage = async (
  slug: string,
  accessCode?: string | null
): Promise<RfpPageResponse> => {
  const response = await request.get(`/rfps/${slug}`, {
    params: {
      for_rfp_page: true,
      access_code: accessCode,
    },
  });

  return response.data;
};

export const getRequestForProposalByRequestId = async (
  requestId: number
): Promise<RfpsShowResponse | null> => {
  try {
    const response = await request.get(`/rfps/${requestId}`, {
      params: { by_id: true },
    });

    return response.data;
  } catch {
    return null;
  }
};

export const getRfpByRequestSlug = async (requestSlug: string): Promise<RfpsShowResponse> => {
  const response = await request.get(`/rfps/${requestSlug}`);
  return response.data;
};

export const createOrUpdateRfp = async (
  rfpData: RfpSubmitData
): Promise<ReviewerDashboardRequest | null> => {
  let response: { data: ReviewerDashboardRequest };

  const formattedData = {
    is_private: rfpData.isPrivate,
    company_research_interest_id: rfpData.areaOfInterestId,
    title: rfpData.title,
    background: rfpData.background,
    looking_for: rfpData.lookingFor,
    request_solutions: rfpData.requestSolutions,
    request_requirements: rfpData.requestRequirements.map((req) => ({
      description: req.description,
      allow_attachments: req.allowAttachments,
    })),
    preferred_requirements: stringArrayToBulletText(rfpData.niceToHaveRequirements),
    out_of_scope: stringArrayToBulletText(rfpData.outOfScope),
    disciplines: rfpData.disciplines,
    areas_of_expertise: rfpData.areasOfExpertise,
    partnership_models: rfpData.partnershipModels,
    trl_range: rfpData.trlRange,
    sponsored_research: rfpData.partnershipModels?.includes(RfpPartnershipModel.SPONSORED_RESEARCH)
      ? rfpData.sponsoredResearch
      : "",
    gift: rfpData.partnershipModels?.includes(RfpPartnershipModel.GIFT) ? rfpData.gift : "",
    expertise: rfpData.expertise,
    tools_and_technology: rfpData.toolsAndTechnology,
    compounds_and_reagents: rfpData.compoundsAndReagents,
    facilities_and_services: rfpData.facilitiesAndServices,
    data: rfpData.data,
    submitted: rfpData.isSubmitted,
    live: !rfpData.forInternalEmployees && rfpData.live,
    has_access_code: rfpData.forInternalEmployees || rfpData.hasAccessCode,
    for_internal_employees: rfpData.forInternalEmployees,
    partner_types: rfpData.partnerTypes,
  };

  Object.keys(formattedData).forEach((key) => {
    if (typeof formattedData[key] === "string") {
      formattedData[key] = formattedData[key].trim();
    }
  });

  try {
    if (rfpData.id) {
      response = await request.put(`/rfps/${rfpData.id}`, {
        rfp: formattedData,
      });
    } else {
      response = await request.post(`/rfps`, {
        rfp: formattedData,
      });
    }

    return response.data;
  } catch (_) {
    return null;
  }
};

export const exportRequestForProposal = (rfpId, rfpIdentifier) => {
  request
    .get(`/rfps/export/${rfpId}.docx`, {
      headers: {
        Accept: "application/octet-stream",
      },
      responseType: "blob",
    })
    .then((response) => {
      if (response.status !== 200) {
        return;
      }

      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        })
      );

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${rfpIdentifier}.docx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    });
};

export const joinRfpWaitlist = async (
  requestId: number,
  {
    email,
    firstName,
    lastName,
  }: {
    email?: string;
    firstName?: string;
    lastName?: string;
  }
): Promise<void> => {
  const response = await request.post(`/rfps/${requestId}/waitlist`, {
    email,
    first_name: firstName,
    last_name: lastName,
  });

  return response.data;
};
