import { BlankModal, Icon } from "@components/library";
import Button from "@components/library/Buttons/Button";
import { COLORS, FONTS } from "@constants";
import { RootState } from "@redux/store";
import { followRequest } from "@requests/userFollowedRequests";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Validator from "validator";

interface Props {
  onSuccess: (requestId: number) => void;
  close: () => void;
  currentRequestId: number;
  open: boolean;
  subheader?: string;
  backgroundMode?: "dim" | "blur";
  initialIsFollowing?: boolean;
}

const NotifyModal = ({
  onSuccess,
  close,
  currentRequestId,
  open,
  subheader = "Get notified when this opportunity becomes available",
  backgroundMode = "dim",
  initialIsFollowing = false,
}: Props) => {
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);

  const [email, setEmail] = useState(currentUser?.email ?? "");
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFollowing, setIsFollowing] = useState(initialIsFollowing);

  const handleOptIn = async () => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    const userEmail = email.trim().toLowerCase();
    if (isEmailValid(userEmail)) {
      try {
        await followRequest(currentRequestId, userEmail, currentUser?.id);

        setIsFollowing(true);
        onSuccess(currentRequestId);
      } catch {
        setError("Something went wrong! Please try again later.");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const isEmailValid = (userEmail) => {
    if (!Validator.isEmail(userEmail)) {
      setError("Sorry, that is not a valid email address.");
      return false;
    }
    return true;
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  return (
    <BlankModal
      onClose={close}
      isOpen={open}
      closeOnDimmerClick={email.length === 0 && error.length === 0}
      width="540px"
      backgroundMode={backgroundMode}
    >
      <ModalContent data-testid="notify-modal">
        <Icon name="Notification Filled" margin="10px auto 0" />
        <Header>Stay in the loop</Header>
        <SubHeader hasSubheader={Boolean(subheader)}>{subheader}</SubHeader>
        {isFollowing ? (
          <>
            <SuccessText data-testid="notify-success-text">
              You&apos;ll be notified.
              {!currentUser.id && " Get ready to apply by building your profile on Halo now."}
            </SuccessText>
            {!currentUser.id && (
              <Link to={`/get-started?email=${email}`}>
                <Button width="100%" margin="16px 0 0" data-testid="create-account-button">
                  Create an account
                </Button>
              </Link>
            )}
          </>
        ) : (
          <>
            {!currentUser.id && (
              <>
                <InputField
                  placeholder="Email Address"
                  onChange={handleChange}
                  autoFocus
                  hasError={error.length > 0}
                  data-testid="email-input"
                />
                <ErrorText hasError={error.length > 0}>{error}</ErrorText>
              </>
            )}
            <Button
              width="100%"
              iconName="Bell"
              onClick={handleOptIn}
              disabled={email.length < 1 || isSubmitting}
              data-testid="notify-me-button"
            >
              Notify me
            </Button>
          </>
        )}
      </ModalContent>
    </BlankModal>
  );
};

export default NotifyModal;

export const ModalContent = styled.div`
  text-align: center;
  justify-content: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 93px;
  width: 100%;
  height: 100%;
`;
export const Header = styled.h3`
  color: ${COLORS.BLACK};
  ${FONTS.HEADING_3_SEMIBOLD};
  margin: 4px 0px 0px 0px;
`;
export const SubHeader = styled.h6`
  width: 355px;
  color: ${COLORS.NEUTRAL_500};
  ${FONTS.REGULAR_2}
`;
export const ErrorText = styled.p`
  color: ${COLORS.RED};
  ${FONTS.REGULAR_2};
  text-align: left;
  margin: 0px 0px 6px 0px;
  display: ${(props) => (props.hasError ? "block" : "none")};
`;
export const InputField = styled.input`
  width: 356px;
  ${FONTS.REGULAR_2};
  height: 39px;
  border: 1px solid ${(props) => (props.hasError ? COLORS.RED : COLORS.NEUTRAL_200)};
  background-color: ${(props) => (props.hasError ? COLORS.RED_100 : "none")};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: ${(props) => (props.hasError ? "10px" : "16px")};
  ::placeholder {
    ${FONTS.REGULAR_2};
  }
`;
export const SuccessText = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${COLORS.GREEN};
  ${FONTS.REGULAR_2}
`;
