import { UserFollowedRequest } from "@tsTypes/schema";
import request from "./request";

export const followRequest = async (
  requestId: number,
  email: string,
  userId?: number
): Promise<UserFollowedRequest> => {
  const response = await request.post("/follow_request", {
    request_id: requestId,
    email,
    user_id: userId,
  });

  return response.data.user_followed_request;
};
export const unfollowRequest = async (requestId: number): Promise<void> => {
  await request.post("/unfollow_request", {
    request_id: requestId,
  });
};
