import { COLORS } from "@constants";
import { RootState } from "@redux/store";
import { getCompany } from "@requests/companies";
import { ShowCompany } from "@tsTypes/index";
import { setPrevUrlCookie } from "@utils/authUtils";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import SignUpBase from "./SignUpBase";

const SignUpPage = () => {
  const [company, setCompany] = useState<ShowCompany | null>(null);
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);

  const history = useHistory();

  const { company_identifier } = useParams();
  const openCallUrl = `/partner/${company_identifier}/submit`;

  if (currentUser?.id) {
    history.push(openCallUrl);
  }

  useEffect(() => {
    (async () => {
      if (company_identifier) {
        const response = await getCompany(company_identifier);
        setCompany(response.company);
        setPrevUrlCookie(openCallUrl);
      }
    })();
  }, [company_identifier]);

  return (
    <Container>
      <SignUpBase company={company} maxWidth="600px" />
    </Container>
  );
};

export default SignUpPage;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${COLORS.BLUE_LIGHT_100};
  display: flex;
  align-items: center;
  justify-content: center;
`;
