import { SSOEnabledHint } from "@components/SSOEnabledHint";
import { Button, Icon, IconButton } from "@components/library";
import { COLORS, FONTS } from "@constants";
import { useSSOSensitiveEmail } from "@hooks/useSSOSensitiveEmail";
import haloLogo from "@images/logos/halo_logo.svg";
import api from "@requests/request";
import { RfpPageRequest } from "@tsTypes/index";
import { identify, track, usedPersonalEmail } from "@utils/appUtils";
import { setPrevUrlCookie } from "@utils/authUtils";
import { css } from "aphrodite";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Form, Grid } from "semantic-ui-react";
import styled from "styled-components";
import Validator from "validator";
import styles from "./LoginModal.styles";
import { ModalType } from "./LoginSignUpModal";

interface Props {
  onClose: () => void;
  previousAction: string;
  setModalType: Dispatch<SetStateAction<ModalType>>;
  currentRFP?: RfpPageRequest;
}

const SignUpModal = ({ onClose, previousAction, setModalType, currentRFP }: Props) => {
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const { email, setEmail, isEmailSSOEnabled, redirectToSSO } = useSSOSensitiveEmail("");
  const [shouldShowSSO, setShouldShowSSO] = useState(isEmailSSOEnabled);

  const easyApply = previousAction === "easy apply";
  const isRfpPage = window.location.pathname.includes("/research/") && currentRFP;
  const isProposalPage = window.location.pathname.startsWith("/proposal/");
  const isMarketplace = window.location.pathname.startsWith("/marketplace");

  let signupHeadline = "Move Science Forward";
  let signupSubtitle = "Find funding opportunities from industry and bring your ideas to life";
  let lineItemsHeader = "";
  let lineItems = [
    "Submit a proposal in less than an hour",
    "Receive feedback directly from the industry contact",
    "Get discovered by industry partners looking for your expertise",
  ];
  if (isMarketplace) {
    signupHeadline = "Explore more opportunities";
    signupSubtitle = "Create a free account to access our full selection of opportunities";
    lineItemsHeader = "Account benefits:";
    lineItems = [
      "Get discovered by industry partners seeking your expertise",
      "Get matched to opportunities that suit your profile",
      "Submit proposals in less than an hour",
      "Receive feedback directly from industry contacts",
    ];
  }

  const isScientist = !isProposalPage;
  const isCompany = isProposalPage;

  let userType = "";
  if (isScientist) {
    userType = "sci";
  } else if (isCompany) {
    userType = "spo";
  } else {
    userType = "adm";
  }

  useEffect(() => {
    const event = `${userType}-create account-start`;
    const type = `modal-${previousAction}`;

    track(event, { type });
  }, [userType, previousAction]);

  useEffect(() => {
    setHasSubmitted(false);
  }, [firstName, lastName, email, password]);

  useEffect(() => {
    if (isEmailSSOEnabled) setShouldShowSSO(true);
  }, [isEmailSSOEnabled]);

  const handleRegister = async () => {
    setIsSubmitting(true);
    setHasSubmitted(true);
    setErrors({});

    if (shouldShowSSO) {
      if (!isEmailSSOEnabled) {
        setErrorMessage("Your email/company is not enabled to use SSO.");
        setIsSubmitting(false);
        return;
      }

      redirectToSSO();
      return;
    }

    if (invalidSubmit()) {
      setIsSubmitting(false);
      return;
    }

    let originalReferrerId;
    if (localStorage?.getItem("original_referrer_id")) {
      originalReferrerId = localStorage.getItem("original_referrer_id");
      localStorage.removeItem("original_referrer_id");
    }

    try {
      const responseJson = await api.post("/users", {
        user: {
          email,
          password,
          first_name: firstName,
          last_name: lastName,
          ...(isCompany && { company: true }),
        },
      });

      if (isRfpPage) {
        if (easyApply && currentRFP.slug) {
          setPrevUrlCookie(`/submit/request_for_proposal/${currentRFP.slug}`, 0);
        } else {
          setPrevUrlCookie();
        }
      }

      const user = responseJson.data;
      identify(user);
      await track(`${userType}-create account-complete`, {
        type: `modal-${previousAction}`,
      });

      if (originalReferrerId) {
        window.location.href = `/redirect_login?referrer_id=${originalReferrerId}`;
      } else {
        window.location.href = "/redirect_login";
      }
    } catch (error) {
      setIsSubmitting(false);
      console.log(error);
      if (error.response.data.errors.email[0].indexOf("already been taken") > -1) {
        setErrorMessage("That email has already been taken.");
      } else if (
        error.response.data.errors.email[0].indexOf("must be a company or university email") > -1
      ) {
        setErrorMessage("Please use company or university email.");
      } else {
        setErrorMessage("Something went wrong. Please try again.");
      }

      setErrors(error.response.data.errors);
    }
  };

  const handleLogin = () => {
    if (isRfpPage) {
      if (easyApply && currentRFP.slug) {
        setPrevUrlCookie(`/submit/request_for_proposal/${currentRFP.slug}`);
      } else {
        setPrevUrlCookie();
      }
    }
    setModalType("login");
  };

  const invalidSubmit = () => {
    if (Validator.isEmpty(firstName) || Validator.isEmpty(lastName) || Validator.isEmpty(email)) {
      setErrorMessage("Please fill in all fields before continuing.");
      return true;
    } else if (!Validator.isEmail(email)) {
      setErrorMessage("Please enter a valid email address.");
      return true;
    } else if (usedPersonalEmail(email)) {
      setErrorMessage(`Please use company ${!isCompany ? "or university " : ""}email.`);
      return true;
    } else if (!Validator.isLength(password, { min: 8, max: 30 })) {
      setErrorMessage("Passwords must be between 8-30 characters long.");
      return true;
    }
  };

  const hasErrors =
    hasSubmitted && (errorMessage.length > 0 || Object.keys(errors || {}).length > 0);

  return (
    <div>
      <div className={css(styles.LoginModal_closeicon)}>
        <IconButton variant="fourth" iconName="Close" onClick={onClose} tooltipPosition="bottom" />
      </div>
      <div className={css(styles.LoginModal_logoContainer)}>
        <a href="/">
          <img src={haloLogo} />
        </a>
      </div>
      <Grid centered columns={2}>
        <Grid.Row centered columns={2}>
          <Grid.Column width={15}>
            <div className={css(styles.LoginModal_hero)}>
              <div className={css(styles.LoginModal_headline)}>{signupHeadline}</div>
              <div className={css(styles.LoginModal_subheadline)}>{signupSubtitle}</div>
              {isScientist && isRfpPage && (
                <div className={css(styles.LoginModal_subheadline)}>
                  This opportunity is for Halo members only. Please create an account to continue.
                </div>
              )}
            </div>
            {lineItems.length > 0 && (
              <div className={css(styles.LoginModal_innerLeftPane)}>
                <p>{lineItemsHeader}</p>
                {lineItems.map((lineItem) => {
                  return (
                    <div className={css(styles.LoginModal_modalText)} key={lineItem}>
                      <span>
                        <Icon name="Verified" color={COLORS.HALO_BLUE} />{" "}
                      </span>
                      <div className={css(styles.LoginModal_lineItem)}>{lineItem}</div>
                    </div>
                  );
                })}
              </div>
            )}
            <div className={css(styles.LoginModal_innerRightPane)}>
              <div
                className={css(
                  errorMessage ? styles.LoginModal_errorMessage : styles.LoginModal_placeholderText
                )}
              >
                {errorMessage ? errorMessage : "Placeholder Text"}
              </div>
              <div>
                <Form error={hasErrors} onSubmit={handleRegister}>
                  {(!shouldShowSSO || firstName || lastName) && (
                    <>
                      <Form.Field
                        className={css(styles.LoginModal_formField, styles.LoginModal_nameField)}
                      >
                        <input
                          className={css(
                            styles.LoginModal_inputField,
                            hasSubmitted && !firstName && styles.LoginModal_errorField
                          )}
                          data-testid="signup-form-first-name-input"
                          name="firstName"
                          onChange={(e) => setFirstName(e.target.value)}
                          placeholder="First Name"
                          value={firstName}
                        />
                      </Form.Field>
                      <Form.Field
                        className={css(styles.LoginModal_formField, styles.LoginModal_nameField)}
                      >
                        <input
                          className={css(
                            styles.LoginModal_inputField,
                            hasSubmitted && !lastName && styles.LoginModal_errorField
                          )}
                          data-testid="signup-form-last-name-input"
                          name="lastName"
                          onChange={(e) => setLastName(e.target.value)}
                          placeholder="Last Name"
                          value={lastName}
                        />
                      </Form.Field>
                    </>
                  )}
                  <Form.Field className={css(styles.LoginModal_formField)}>
                    <input
                      className={css(
                        styles.LoginModal_inputField,
                        (usedPersonalEmail(email) || (hasSubmitted && !Validator.isEmail(email))) &&
                          styles.LoginModal_errorField
                      )}
                      data-testid="signup-form-email-input"
                      name="email"
                      onChange={(e) => setEmail(e.target.value)}
                      placeholder={isCompany ? "Company Email" : "Company or University Email"}
                      value={email}
                    />
                  </Form.Field>
                  {shouldShowSSO ? (
                    <>
                      <SSOHintContainer>
                        <SSOEnabledHint />
                      </SSOHintContainer>
                      <Button
                        data-testid="signup-form-submit-button"
                        disabled={isSubmitting || !email}
                        type="submit"
                        width="100%"
                      >
                        Continue
                      </Button>
                      <SSOToggleContainer
                        onClick={() => {
                          if (isEmailSSOEnabled) setEmail("");
                          setShouldShowSSO(false);
                        }}
                      >
                        <SSOToggle>Use email/password instead</SSOToggle>
                      </SSOToggleContainer>
                    </>
                  ) : (
                    <>
                      <Form.Field className={css(styles.LoginModal_formField)}>
                        <input
                          className={css(
                            styles.LoginModal_inputField,
                            hasSubmitted &&
                              (password.length < 8 || password.length > 30) &&
                              styles.LoginModal_errorField
                          )}
                          data-testid="signup-form-password-input"
                          name="password"
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Password"
                          type="password"
                          value={password}
                        />
                      </Form.Field>
                      <Button
                        data-testid="signup-form-submit-button"
                        disabled={isSubmitting}
                        type="submit"
                        width="100%"
                      >
                        Create Account
                      </Button>
                      <SSOToggleContainer>
                        <SSOToggle onClick={() => setShouldShowSSO(true)}>
                          Sign in with SSO
                        </SSOToggle>
                      </SSOToggleContainer>
                    </>
                  )}
                </Form>
                <div className={css(styles.LoginModal_forgotPasswordContainer)}>
                  <div className={css(styles.LoginModal_footerText)}>
                    By signing up you agree to the{" "}
                  </div>
                  <a
                    className={css(styles.LoginModal_forgotPassword)}
                    href="/terms"
                    target="_blank"
                  >
                    Terms of Service
                  </a>
                  <span className={css(styles.LoginModal_footerText)}>{" & "}</span>
                  <a
                    className={css(styles.LoginModal_forgotPassword)}
                    href="/privacy"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </div>
            <hr className={css(styles.LoginModal_horizontalRule)} />
            <div className={css(styles.LoginModal_alreadyHaveAccount)}>
              Already have an account?
              <span style={{ marginLeft: "2px" }}>
                <div className={css(styles.LoginModal_signInLink)} onClick={handleLogin}>
                  Sign in
                </div>
              </span>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default SignUpModal;

const SSOHintContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px;
`;

const SSOToggleContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const SSOToggle = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  text-decoration: underline;
  color: ${COLORS.BLACK};
  ${FONTS.REGULAR_2}

  &:hover {
    cursor: pointer;
    color: ${COLORS.BLUE_900};
  }
`;
