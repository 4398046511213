import Step3CompanyScientist from "./CompanyScientist";
import Step3CompanySponsor from "./CompanySponsor";
import Step3ResearchInstitutionAdmin from "./ResearchInstitutionAdmin";
import Step3ResearchInstitutionScientist from "./ResearchInstitutionScientist";

import { Button, SingleSelectDropdown, TextInput } from "@components/library";
import { COLORS, FEATURE_FLAGS, WINDOW_DIMENSIONS } from "@constants";
import { getOptionFromValue, getValueFromOption } from "@utils/dropdownUtils";
import { t } from "@utils/i18n";
import { isValidURL } from "@utils/textUtils";
import styled from "styled-components";
import * as S from "../StepStyles";

export const COMPANY_SPONSOR_TITLE_LIMIT = 50;

export const HOW_DID_YOU_HEAR_ABOUT_US_OTHER_OPTION = "Other (describe below)";
const HOW_DID_YOU_HEAR_ABOUT_US_OPTIONS = [
  "I received an email from Halo",
  "I received an email from a university administrator",
  "I received an email from a company/organization",
  "I received an email from a fellow researcher/startup",
  "I was actively searching for partnering opportunities",
  "I read an article or listened to a podcast",
  HOW_DID_YOU_HEAR_ABOUT_US_OTHER_OPTION,
].map(getOptionFromValue);

const Step3 = ({
  userType,
  handleOnboard,
  onBack,
  profileInfo,
  setProfileInfo,
  user,
  isSubmitting,
  selectedGoogleScholarAuthor,
}) => {
  const {
    company,
    role,
    title,
    companyName,
    companyURL,
    country_code,
    disciplines,
    areasOfExpertise,
    university,
    universityExpertise,
    universityHasCountry,
    howDidYouHearAboutUs,
  } = profileInfo;

  let disabled = isSubmitting;
  if (userType === "company-sponsor")
    disabled ||= !(company && title && title.length <= COMPANY_SPONSOR_TITLE_LIMIT);
  if (userType === "company-scientist")
    disabled ||= !(
      companyName &&
      isValidURL(companyURL) &&
      country_code &&
      role &&
      disciplines?.length &&
      areasOfExpertise?.length
    );
  if (userType === "research-administrator")
    disabled ||= !(
      university &&
      (FEATURE_FLAGS.UNIVERSITY_ADMIN_DISCIPLINES ? disciplines?.length : universityExpertise) &&
      (universityHasCountry || country_code)
    );
  if (userType === "research-scientist")
    disabled ||= !(
      university &&
      role &&
      disciplines?.length &&
      areasOfExpertise?.length &&
      (universityHasCountry || country_code)
    );

  const onContinue = () => {
    if (userType === "company-sponsor") return handleOnboard("sponsor");
    if (userType === "research-administrator") return handleOnboard("admin");
    return handleOnboard("scientist");
  };

  let stepSubheading = "";
  let stepHeading = "";
  if (selectedGoogleScholarAuthor) {
    stepSubheading = "Confirm imported information";
    stepHeading = "Here's what we gathered from Google Scholar.\nWe'll import more after signup.";
  } else {
    stepSubheading = "Enter profile information";
    switch (userType) {
      case "research-scientist":
      case "company-scientist":
        stepHeading =
          "Create a profile to start offering your expertise.\nYou can add more information later.";
        break;
      case "company-sponsor":
        stepHeading = "Create a profile to start finding solutions and experts.";
        break;
      case "research-administrator":
        stepHeading = "Create a profile to start connecting faculty with relevant opportunities.";
        break;
    }
  }

  return (
    <Container center={!selectedGoogleScholarAuthor}>
      <S.StepContainer width="592px">
        <S.StepHeader subheading={stepSubheading} heading={stepHeading} />
        {userType === "company-sponsor" && (
          <Step3CompanySponsor
            profileInfo={profileInfo}
            setProfileInfo={setProfileInfo}
            email={user.email}
          />
        )}
        {userType === "company-scientist" && (
          <Step3CompanyScientist
            profileInfo={profileInfo}
            setProfileInfo={setProfileInfo}
            selectedGoogleScholarAuthor={selectedGoogleScholarAuthor}
          />
        )}
        {userType === "research-administrator" && (
          <Step3ResearchInstitutionAdmin
            profileInfo={profileInfo}
            setProfileInfo={setProfileInfo}
          />
        )}
        {userType === "research-scientist" && (
          <Step3ResearchInstitutionScientist
            profileInfo={profileInfo}
            setProfileInfo={setProfileInfo}
            selectedGoogleScholarAuthor={selectedGoogleScholarAuthor}
          />
        )}
        {FEATURE_FLAGS.HOW_DID_YOU_HEAR_ABOUT_US && (
          <S.DropdownRow>
            <S.DropdownTitle>
              {t("pages.onboarding_container.how_did_you_hear_about_us.label")}
            </S.DropdownTitle>
            <S.DropdownSubtitle>Optional</S.DropdownSubtitle>
            <SingleSelectDropdown
              placeholder={t("pages.onboarding_container.how_did_you_hear_about_us.placeholder")}
              placeholderColor={COLORS.NEUTRAL_400}
              options={HOW_DID_YOU_HEAR_ABOUT_US_OPTIONS}
              onChange={(option) =>
                setProfileInfo({ ...profileInfo, howDidYouHearAboutUs: getValueFromOption(option) })
              }
              value={getOptionFromValue(howDidYouHearAboutUs)}
            />
            {howDidYouHearAboutUs === HOW_DID_YOU_HEAR_ABOUT_US_OTHER_OPTION && (
              <>
                <br />
                <TextInput
                  placeholder="Please describe"
                  placeholderColor={COLORS.NEUTRAL_400}
                  value={profileInfo.howDidYouHearAboutUsOther}
                  onChange={(e) => {
                    setProfileInfo({ ...profileInfo, howDidYouHearAboutUsOther: e.target.value });
                  }}
                  hideClearButton
                />
              </>
            )}
          </S.DropdownRow>
        )}
        <S.Hairline />
        <S.ButtonContainer>
          <Button type="button" variant="ghost" width="120px" onClick={() => onBack("step2")}>
            Back
          </Button>
          <Button
            type="button"
            disabled={disabled}
            onClick={onContinue}
            data-testid="company-sponsor-step-4-submit-button"
            width="120px"
          >
            Complete signup
          </Button>
        </S.ButtonContainer>
      </S.StepContainer>
    </Container>
  );
};

export default Step3;
const Container = styled.div`
  text-align: left;
  padding: 0;
  margin-bottom: 200px;
  ${({ center }) => !center && `width: 800px;`}

  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    padding: 0 30px;
    width: 100%;
  }
`;
