import { COLORS, FONTS, WINDOW_DIMENSIONS } from "@constants";
import haloLogoLight from "@images/halo-inverse.svg";
import haloLogoDark from "@images/logos/halo_logo.svg";
import { CompanyPartial, ShowCompany } from "@tsTypes/index";
import { CompanyBranding } from "@tsTypes/schema";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import NavbarPopup from "./NavbarPopup";

interface Props {
  company: ShowCompany | CompanyPartial | null;
  branding: CompanyBranding | null;
  accent?: boolean;
  children?: ReactNode[];
}

const Navbar = ({ company, branding, accent, children }: Props) => {
  const maxHeight = (accent && "84px") || "68px";
  const companyLogo = (accent && branding?.navbar_logo_url) || company?.logo?.url;
  const companyLogoHeight = (accent && "64px") || "54px";
  const background = (accent && branding?.accent_color) || COLORS.WHITE;
  const haloLogo = (accent && background !== COLORS.WHITE && haloLogoLight) || haloLogoDark;
  const color = (accent && branding?.navbar_text_color) || COLORS.BLACK;
  const borderColor = (accent && branding?.accent_color) || COLORS.NEUTRAL_300;

  return (
    <Container
      maxHeight={maxHeight}
      background={background}
      color={color}
      borderColor={borderColor}
    >
      <LeftContent>
        <Link to={`/company/${company?.identifier}`}>
          {company ? (
            <Logo
              height={companyLogoHeight}
              src={companyLogo}
              alt={`${company?.company_name} logo`}
            />
          ) : (
            <PlaceholderLogo height={companyLogoHeight} />
          )}
        </Link>
        Powered by
        <Link to={`/`}>
          <Logo height="32px" src={haloLogo} alt="Halo logo" />
        </Link>
      </LeftContent>
      <RightContent>{children}</RightContent>
      {accent && (
        <NarrowRightContent>
          <NavbarPopup branding={branding} />
        </NarrowRightContent>
      )}
    </Container>
  );
};

export default Navbar;

const Container = styled.div`
  ${FONTS.REGULAR_2};

  width: 100%;
  padding: 30px 65px 30px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: ${({ maxHeight }) => maxHeight};
  background: ${({ background }) => background};
  color: ${({ color }) => color};
  border-bottom: 1px solid ${({ borderColor }) => borderColor};
`;

const Content = styled.div`
  display: flex;
  align-items: center;
`;

const LeftContent = styled(Content)`
  gap: 16px;
`;

const RightContent = styled(Content)`
  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    display: none;
  }
`;

const NarrowRightContent = styled(Content)`
  display: none;
  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    display: block;
  }
`;

const Logo = styled.img`
  height: ${({ height }) => height};
  margin-right: 24px;
`;

const PlaceholderLogo = styled.div`
  width: ${({ height }) => height};
  margin-right: 24px;
`;
