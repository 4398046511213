import { InfoBanner, SingleSelectDropdown, UniversityDropdown } from "@components/library";
import KeywordsDropdown from "@components/library/Dropdowns/KeywordsDropdown/KeywordsDropdown";
import { COUNTRY_OPTIONS, FEATURE_FLAGS } from "@constants";
import { getOptionFromValue, getValueFromOption } from "@utils/dropdownUtils";
import { useEffect } from "react";
import api from "src/requests/request";
import * as S from "../StepStyles";

export const SCIENTIST_ROLES = [
  "Principal Investigator",
  "Postdoctoral Scientist",
  "Research Associate",
  "PhD Candidate",
];

export const SCIENTIST_ROLE_OPTIONS = SCIENTIST_ROLES.map(getOptionFromValue);

const SCIENTIST_ROLE_OPTIONS_OLD = SCIENTIST_ROLES.map((text) => ({
  label: text,
  value: text,
}));

function ResearchInstitutionScientist({
  profileInfo,
  setProfileInfo,
  prefillInstitutionName = "",
  selectedGoogleScholarAuthor,
}) {
  const { university, universityHasCountry, role, disciplines, areasOfExpertise, countryOption } =
    profileInfo;

  const handleSelectUniversityOption = async (option) => {
    if (!option) return;

    const responseJson = await api.get("/universities/has_country", {
      params: { name: getValueFromOption(option) },
    });
    const { has_country } = responseJson.data;

    setProfileInfo((oldProfileInfo) => ({
      ...oldProfileInfo,
      university: getValueFromOption(option),
      universityHasCountry: has_country,
    }));
  };

  // Prefill data if author is selected
  useEffect(() => {
    if (!selectedGoogleScholarAuthor) return;
    setProfileInfo((oldProfileInfo) => ({
      ...oldProfileInfo,
      disciplines: selectedGoogleScholarAuthor.disciplines,
      areasOfExpertise: selectedGoogleScholarAuthor.areas_of_expertise,
    }));
    // step2: university, step3a: university (match!)
    if (selectedGoogleScholarAuthor.is_university_scientist) {
      // pre-fill university, has_country, title
      handleSelectUniversityOption(getOptionFromValue(selectedGoogleScholarAuthor.location));
      setProfileInfo((oldProfileInfo) => ({
        ...oldProfileInfo,
        role: selectedGoogleScholarAuthor.title,
      }));
    }
  }, []);

  const googleScholarInstitutionNotFound = Boolean(
    FEATURE_FLAGS.GOOGLE_SCHOLAR_ONBOARDING_STEP &&
      selectedGoogleScholarAuthor &&
      !selectedGoogleScholarAuthor.is_university_scientist
  );

  return (
    <>
      <InfoBanner
        shouldDisplay={googleScholarInstitutionNotFound}
        text={
          <div>
            The institution listed in your Google Scholar profile is not currently in our database.
            You can{" "}
            <a
              href="/contact?message=Hello, I can't find my institution as an option. My institution is: "
              target="_blank"
            >
              request that we add your institution
            </a>{" "}
            or choose a different institution below.
          </div>
        }
        type="ghost"
        margin="0 0 32px"
        padding="0"
      />
      <S.DropdownRow>
        <S.DropdownTitle>Research institution</S.DropdownTitle>
        {prefillInstitutionName.length > 0 ? (
          <S.Input type="text" value={prefillInstitutionName} tabIndex={-1} fixed readOnly />
        ) : (
          <>
            <UniversityDropdown
              value={getOptionFromValue(university)}
              onChange={handleSelectUniversityOption}
              data-testid="ri-scientist-institution-name-input"
            />
            {!googleScholarInstitutionNotFound && (
              <S.ContactLink>
                You can{" "}
                <a
                  href="/contact?message=Hello, I can't find my institution as an option. My institution is: "
                  target="_blank"
                >
                  request that we add your institution
                </a>{" "}
                if you don&rsquo;t see it above.
              </S.ContactLink>
            )}
          </>
        )}
      </S.DropdownRow>
      {!universityHasCountry && prefillInstitutionName.length === 0 && (
        <S.DropdownRow>
          <S.DropdownTitle>Institution location</S.DropdownTitle>
          <SingleSelectDropdown
            withSearchIcon
            placeholder="Search countries"
            value={countryOption}
            options={COUNTRY_OPTIONS}
            onChange={(option) =>
              setProfileInfo((oldProfileInfo) => ({
                ...oldProfileInfo,
                countryOption: option,
                country_code: getValueFromOption(option),
              }))
            }
            data-testid="ri-scientist-institution-location-input"
          />
        </S.DropdownRow>
      )}
      <S.DropdownRow>
        <S.DropdownTitle>Which of the following best describes your role?</S.DropdownTitle>
        <SingleSelectDropdown
          placeholder="Select your role"
          options={SCIENTIST_ROLE_OPTIONS_OLD}
          onChange={(e) =>
            setProfileInfo((oldProfileInfo) => ({
              ...oldProfileInfo,
              role: getValueFromOption(e),
            }))
          }
          value={getOptionFromValue(role)}
          data-testid="ri-scientist-role-input"
        />
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>Which disciplines are most relevant to your work?</S.DropdownTitle>
        <S.DropdownSubtitle>Choose between 1 and 3 terms.</S.DropdownSubtitle>
        <KeywordsDropdown
          type="disciplines"
          value={disciplines ?? []}
          onChange={(selection) => setProfileInfo({ ...profileInfo, disciplines: selection })}
          maxValues={3}
          placeholder="Search disciplines"
          showSuggestions
        />
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>What is your expertise?</S.DropdownTitle>
        <S.DropdownSubtitle>Choose between 3 and 10 terms.</S.DropdownSubtitle>
        <KeywordsDropdown
          type="areas_of_expertise"
          value={areasOfExpertise ?? []}
          onChange={(selection) => setProfileInfo({ ...profileInfo, areasOfExpertise: selection })}
          maxValues={10}
          placeholder="Search areas of expertise"
          showSuggestions
        />
      </S.DropdownRow>
    </>
  );
}

export default ResearchInstitutionScientist;
