import { format } from "date-fns";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import styled from "styled-components";

import useProposalDetailTabs from "@components/ProposalDetailTabs";
import { Button, Icon, UserPreview } from "@components/library";
import SlideModalBase, { SlideModalBaseProps } from "@components/library/ModalBases/SlideModalBase";
import { COLORS, FONTS, PROPOSAL_TYPES } from "@constants";
import { getProposalDataBySlug } from "@requests/proposals";
import { RequestableType } from "@tsTypes/requests";
import ProposalShareModal from "./ProposalShareModal";

interface Props extends SlideModalBaseProps {
  isOpen: boolean;
  onClose: () => void;
  proposalSlug: string;
}

const ProposalQuickViewModal = ({ isOpen, onClose, proposalSlug }: Props) => {
  if (!isOpen) return null;

  const [isLoading, setIsLoading] = useState(true);
  const [proposal, setProposal] = useState<Record<string, any>>({});
  const [publications, setPublications] = useState<Record<string, any>[]>([]);
  const [fundings, setFundings] = useState<Record<string, any>[]>([]);
  const [patents, setPatents] = useState<Record<string, any>[]>([]);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const { ProposalDetailTabs, ProposalDetailTabsContent } = useProposalDetailTabs({
    proposal,
    publications,
    fundings,
    patents,
  });

  useEffect(() => {
    if (isOpen) {
      (async () => {
        const response = await getProposalDataBySlug(proposalSlug);

        setProposal(response.proposal);
        setPublications(response.proposal_publications);
        setFundings(response.proposal_fundings);
        setPatents(response.proposal_patents);
        setIsLoading(false);
      })();
    }
  }, [isOpen]);

  const hasMaterials = Boolean(publications.length + fundings.length + patents.length);
  const { request } = proposal;

  const isChemicalCompoundProposal = proposal.type === PROPOSAL_TYPES.MATERIALS;

  return (
    <>
      <ProposalShareModal
        proposal={proposal}
        isOpen={isShareModalOpen}
        onClose={() => setIsShareModalOpen(false)}
      />
      <SlideModalBase isOpen={isOpen} onClose={onClose}>
        <Container data-testid="proposal-quick-view-modal">
          {isLoading ? (
            <Loader active style={{ top: "30%" }} />
          ) : (
            <>
              <TopContent>
                <Title data-testid="title">
                  {(!isChemicalCompoundProposal && proposal.title) || "Untitled Proposal"}
                </Title>
                <ProposalInfo
                  as={Link}
                  to={
                    request.requestable_type === RequestableType.OPEN_CALL
                      ? `/company/${request.company.identifier}`
                      : `/research/${request.requestable.rfp_program_identifier}/${request.slug}`
                  }
                  disabled={!request.slug}
                  target="_blank"
                >
                  <Icon name="Opportunity" size="xs" margin="0 8px 0 0" />
                  <LinkText data-testid="request-title">{proposal.request.title}</LinkText>
                </ProposalInfo>
                <ProposalInfo>
                  <Icon name="Calendar" size="xs" margin="0 8px 0 0" />
                  Submitted on {format(new Date(proposal.first_completed_at), "MMMM d, y")}
                </ProposalInfo>
                <UserPreviewContainer>
                  <UserPreview user={proposal.user} />
                </UserPreviewContainer>
                <Buttons>
                  <Button
                    type="button"
                    size="sm"
                    iconName="Share"
                    margin="0 32px 0 0"
                    onClick={() => setIsShareModalOpen(true)}
                    data-testid="share-proposal-button"
                  >
                    Share Proposal
                  </Button>
                  <SeeFullButton to={`/proposal/${proposalSlug}`} target="_blank">
                    Open in a new tab&nbsp;
                    <Icon
                      name="Open in a new tab"
                      size="xs"
                      color={COLORS.NEUTRAL_500}
                      margin="0 0 0 4px"
                    />
                  </SeeFullButton>
                </Buttons>
                <ProposalDetailsLabel hasMaterials={hasMaterials}>
                  Proposal details:
                </ProposalDetailsLabel>
                {ProposalDetailTabs}
              </TopContent>
              <BottomContent data-testid="tab-contents">{ProposalDetailTabsContent}</BottomContent>
            </>
          )}
        </Container>
      </SlideModalBase>
    </>
  );
};

export default ProposalQuickViewModal;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 622px;
  ${FONTS.REGULAR_2};
`;
const TopContent = styled.div`
  padding: 50px 24px 0;
`;
const BottomContent = styled.div`
  padding: 0 24px 24px;
  overflow-y: auto;
`;
const Title = styled.div`
  ${FONTS.HEADING_4_SEMIBOLD};
  margin-bottom: 16px;
  margin-right: 48px;
`;
const ProposalInfo = styled.div`
  display: flex;
  align-items: center;
  ${FONTS.REGULAR_3};
  margin-bottom: 4px;
  color: ${COLORS.BLACK};
  ${({ disabled }) => disabled && "pointer-events: none"};
  &:hover {
    color: ${COLORS.BLACK};
  }
  &:focus {
    text-decoration: none;
    outline: none;
    color: ${COLORS.BLACK};
  }
`;
const LinkText = styled.span`
  &:hover {
    text-decoration: underline;
  }
`;
const UserPreviewContainer = styled.div`
  margin: 24px 0 32px;
`;
const Buttons = styled.div`
  display: flex;
  margin-bottom: 32px;
`;
const SeeFullButton = styled(Link)`
  display: flex;
  align-items: center;
  border: none;
  background-color: transparent;
  ${FONTS.MEDIUM_2};
  color: ${COLORS.NEUTRAL_500};
  &:hover,
  &:focus {
    text-decoration: none;
    color: ${COLORS.NEUTRAL_500};
  }
`;
const ProposalDetailsLabel = styled.div`
  ${({ hasMaterials }) => (hasMaterials ? FONTS.MEDIUM_3 : FONTS.MEDIUM_2)}
  margin-bottom:  ${({ hasMaterials }) => (hasMaterials ? "8px" : "16px")};
`;
const TabPanel = styled.div`
  ${FONTS.REGULAR_2};
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${COLORS.NEUTRAL_300};
  }
  /* Aphrodite uses !important, so this is needed to override it until a new materials row component can be created */
  & > div {
    margin-top: 0 !important;
    & > div {
      padding-top: 0 !important;
    }
  }
`;
const Header = styled.div`
  ${FONTS.SEMIBOLD_1};
  &:not(:first-of-type) {
    margin-top: 24px;
  }
`;
