import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { COLORS, FONTS } from "@constants";
import { fetchCurrentUser } from "@redux/actions/profileActions";
import { RootState } from "@redux/store";
import { getCompany } from "@requests/companies";
import { updateUserKeywords, updateUserProfile } from "@requests/users";
import { SponsorRole } from "@tsTypes/sponsorProfiles";
import { getOptionFromValue } from "@utils/dropdownUtils";
import { USER_ROLES } from "src/constants/users";

import { BlankModal, Button, SingleSelectDropdown, TextButton } from "@components/library";
import { DropdownOption } from "@components/library/Dropdowns/DropdownOption";
import KeywordsDropdown from "@components/library/Dropdowns/KeywordsDropdown/KeywordsDropdown";
import { BlankModalProps } from "@components/library/ModalBases/BlankModal";

import haloLogo from "@images/logos/halo_logo.svg";
import { sponsorOnboarded } from "@requests/hubspot";
import { ShowCompany } from "@tsTypes/index";
import appsignal from "@utils/appsignal";
import { t } from "@utils/i18n";

const SPONSOR_ROLES_DROPDOWN_OPTIONS = Object.values(SponsorRole).map(
  getOptionFromValue
) as DropdownOption[];

const SSOSponsorOnboardingModal = ({ isOpen, onClose }: BlankModalProps) => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);
  const isCommenter = currentUser.profile_info.commenter;

  const [step, setStep] = useState<1 | 2>(1);
  const [company, setCompany] = useState<ShowCompany>({} as ShowCompany);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [title, setTitle] = useState(currentUser.profile_info.title);
  const [role, setRole] = useState(currentUser.profile_info.role);
  const [areasOfExpertise, setAreasOfExpertise] = useState<string[]>(
    currentUser.areas_of_expertise
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setIsSubmitting(true);

    try {
      if (step === 1) {
        await updateUserProfile(currentUser.id, USER_ROLES.sponsor, {
          ...currentUser.profile_info,
          title,
          role,
        });

        sponsorOnboarded(currentUser);

        dispatch(fetchCurrentUser());

        setStep(2);
      } else {
        const response = await updateUserKeywords(currentUser.id, {
          disciplines: [],
          areasOfExpertise,
        });

        if (response.data.success) {
          dispatch(fetchCurrentUser());
        }

        onClose();
      }
    } catch (err) {
      appsignal.sendError(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSkip = (e) => {
    e.preventDefault();
    onClose();
  };

  useEffect(() => {
    (async () => {
      try {
        if (!currentUser?.profile_info?.company_identifier) {
          return;
        }

        const response = await getCompany(currentUser.profile_info.company_identifier);

        setCompany(response.company);
      } catch (err) {
        appsignal.sendError(err);
      }
    })();
  }, [currentUser]);

  if (!company?.id) return null;

  return (
    <BlankModal
      isOpen={isOpen}
      onClose={onClose}
      closeOnDimmerClick={false}
      hideCloseButton
      width="540px"
    >
      <Container onSubmit={handleSubmit}>
        <Top>
          <Logo src={haloLogo} alt="Halo logo" />
          <CompanyLogo src={company.logo?.url} />
        </Top>
        {(() => {
          switch (step) {
            case 1:
              return (
                <>
                  <Bottom>
                    <Header>
                      {t(
                        [
                          "components.modals.sso_sponsor_onboarding_modal",
                          isCommenter ? "commenter_header" : "normal_header",
                        ],
                        { company_name: company.company_name }
                      )}
                    </Header>
                    <SingleSelectDropdown
                      label="What's your role?"
                      value={role ? { value: role, label: role } : null}
                      options={SPONSOR_ROLES_DROPDOWN_OPTIONS}
                      onChange={(newOption) => {
                        if (newOption) {
                          setRole(newOption.value);
                        }
                      }}
                      placeholder="Select your role"
                      isPortal={true}
                    />
                    <div>
                      <Title>What&rsquo;s your job title?</Title>
                      <Input
                        key="title-input-id"
                        type="text"
                        name="title"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                        autoComplete="off"
                        data-testid="scinet-signup-title-input"
                        placeholder="Enter your job title"
                      />
                    </div>
                  </Bottom>
                  <ButtonContainer>
                    <Button type="submit" disabled={!role || !title || isSubmitting}>
                      Submit
                    </Button>
                  </ButtonContainer>
                </>
              );
            case 2:
              return (
                <>
                  <Bottom>
                    <div style={{ margin: "0 0 8px" }}>
                      Thanks for creating an account.
                      <br />
                      Complete your profile so other teams at {company.company_name} can find you.
                    </div>
                    <div>
                      <Label>Areas of expertise</Label>
                      <KeywordsDropdown
                        type="areas_of_expertise"
                        value={areasOfExpertise}
                        onChange={setAreasOfExpertise}
                        maxValues={5}
                        placeholder="Select"
                        isPortal
                        showSuggestions
                      />
                      <Sublabel>
                        e.g. Diagnostics, Neurodegeneration
                        <FloatRight>Select up to 5</FloatRight>
                      </Sublabel>
                    </div>
                  </Bottom>
                  <ButtonContainer>
                    <TextButton text="Skip" onClick={handleSkip} iconName={null} />
                    <Button disabled={isSubmitting || !areasOfExpertise.length}>
                      Add to profile
                    </Button>
                  </ButtonContainer>
                </>
              );
            default:
              return null;
          }
        })()}
      </Container>
    </BlankModal>
  );
};

const Container = styled.form`
  ${FONTS.REGULAR_2};
  b {
    ${FONTS.BOLD_2};
  }
`;

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  padding: 16px 32px;

  border-bottom: 1px solid ${COLORS.NEUTRAL_200};
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;

  padding: 24px 32px;

  ${FONTS.REGULAR_2};

  border-bottom: 1px solid ${COLORS.NEUTRAL_200};
`;

const Logo = styled.img`
  height: 32px;
`;

const CompanyLogo = styled.img`
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 8px;

  width: 64px;
  height: 64px;

  border: 1px solid ${COLORS.NEUTRAL_250};
  border-radius: 12px;

  object-fit: contain;
`;

const Header = styled.div`
  ${FONTS.HEADING_5_MEDIUM}

  margin: 0 0 24px;
`;

const Title = styled.label`
  display: flex;
  flex-direction: column;

  width: 100%;
  margin: 0 0 10px;

  ${FONTS.SEMIBOLD_2};
`;

const Input = styled.input`
  height: 36px;
  width: 100%;
  padding: 10px;

  border: solid 1px ${COLORS.NEUTRAL_300};
  ${({ error }) => error && `border: solid 1px ${COLORS.RED};`}
  border-radius: 6px;

  ${FONTS.REGULAR_2}
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 24px;

  width: 100%;

  padding: 16px 32px;
`;

const Label = styled.div`
  ${FONTS.MEDIUM_2}
`;

const Sublabel = styled.div`
  ${FONTS.REGULAR_3}
  width: 100%;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 16px;
`;

const FloatRight = styled.span`
  float: right;
`;

export default SSOSponsorOnboardingModal;
