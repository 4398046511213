import api from "@requests/request";
import { ProposalyType } from "@tsTypes/proposaly";

export async function fetchEmailResponseTemplates(company_id, request) {
  const responseJson = await api.get(`/email_response_templates?company_id=${company_id}`);
  let templates = responseJson.data.email_response_templates;

  // Filter out default templates that contain the company name for confidential RFPs
  if (request?.confidential) {
    templates = templates.filter(
      (template) => template.company_id || !template.content?.includes("[COMPANY")
    );
  }

  return templates;
}

export async function submitProposalContact({
  message,
  proposalyType,
  proposalyId,
  status,
}: {
  message?: string;
  proposalyType: ProposalyType;
  proposalyId: number;
  status: number;
}) {
  const data = {
    message: message,
    proposaly_type: proposalyType,
    proposaly_id: proposalyId,
    status,
  };

  const responseJson = await api.post("/notifications/proposal_contact", data);

  return responseJson;
}

// massAdvanceProposals
export async function updateProposalStatuses(data: {
  proposaly_type: ProposalyType;
  proposaly_ids: number[];
  status: number;
  message: string;
}) {
  const responseJson = await api.post("/proposals/status", data);

  return responseJson;
}

export async function sponsorShareProposal({ message, proposal_id, email }) {
  const data = {
    message,
    proposal_id,
    email,
  };
  const responseJson = await api.post("/notifications/sponsor_share_proposal", data);

  return responseJson;
}

export async function fetchProposalsSortedByScore({ rfp_id, status, type }) {
  const responseJson = await api.get("/proposals/sort_proposals_by_score", {
    params: {
      rfp_id,
      status,
      is_user_score: type === "My Score",
    },
  });
  return responseJson.data.proposals;
}
