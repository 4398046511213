import { Button } from "@components/library";
import { COLORS, FEATURE_FLAGS, FONTS } from "@constants";
import { signOut } from "@requests/users";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { track } from "@utils/appUtils";
import { assertAbsurd } from "@utils/tsUtils";
import { useEffect } from "react";
import styled from "styled-components";
import RadioButton from "../../../components/libraryOld/RadioButtons/RadioButton";
import { UserDetailedType, UserInfo, UserIntent } from "../OnboardingContainer";
import * as S from "../StepStyles";

interface Props {
  values: UserInfo;
  setWork: (v?: string) => void;
  setRole: (v?: string) => void;
  setIntent: (v?: UserIntent) => void;
  setDetailedType: (v?: UserDetailedType) => void;
  onContinue: () => void;
}

function Step2({ values, setWork, setRole, setIntent, setDetailedType, onContinue }: Props) {
  const { work, role, intent, detailedType } = values;
  const isWhiteLabel = location.pathname.startsWith("/partner");

  useEffect(() => {
    setDetailedType(undefined);
    setRole(undefined);

    if (intent) {
      track(SegmentEventName.Click, {
        react_component: "Onboarding - Step2",
        ui_component: "Intent selection",
        intent,
      });
    }
  }, [intent]);

  useEffect(() => {
    switch (detailedType) {
      case UserDetailedType.COMPANY_SPONSOR:
      case UserDetailedType.VENTURE_CAPITAL_SPONSOR:
      case UserDetailedType.GOVERNMENT_SPONSOR:
        setRole("company-sponsor");
        break;
      case UserDetailedType.UNIVERSITY_SCIENTIST:
        setRole("research-scientist");
        break;
      case UserDetailedType.STARTUP_SCIENTIST:
      case UserDetailedType.SUPPLIER:
      case UserDetailedType.CONSULTANT:
        setRole("company-scientist");
        break;
      case UserDetailedType.UNIVERSITY_ADMINISTRATOR:
        setRole("research-administrator");
        break;
      case undefined:
        break;
      default:
        assertAbsurd(detailedType);
    }

    if (detailedType) {
      track(SegmentEventName.Click, {
        react_component: "Onboarding - Step2",
        ui_component: "Detailed type selection",
        detailed_type: detailedType,
      });
    }
  }, [detailedType]);

  if (isWhiteLabel) {
    return (
      <S.StepContainer>
        <S.StepHeader
          subheading="Welcome to Halo"
          heading="Tell us about how you're looking to use Halo."
        />
        <S.Padding height={25} />
        <RadioButton
          testid="ri-scientist-radio-button"
          text="Research Institution"
          description="e.g. universities, research center"
          value="research-scientist"
          selected={role}
          onChange={(v) => setRole(v)}
        />
        <RadioButton
          testid="company-scientist-radio-button"
          text="Company"
          description="e.g. startups, suppliers"
          value="company-scientist"
          selected={role}
          onChange={(v) => setRole(v)}
        />
        <ButtonContainer work={work}>
          <Button
            type="button"
            data-testid="step2-submit-button"
            disabled={role === ""}
            onClick={onContinue}
            width="120px"
          >
            Continue
          </Button>
        </ButtonContainer>
      </S.StepContainer>
    );
  }

  return (
    <S.StepContainer width="592px">
      <S.StepHeader
        subheading="Welcome to Halo"
        heading="Tell us about how you're looking to use Halo."
      />
      {FEATURE_FLAGS.ONBOARDING_V2 ? (
        <>
          <RadioHeading>What would you like to do at Halo?</RadioHeading>
          <RadioContainer>
            <Radio
              selected={intent === UserIntent.FIND_SOLUTIONS}
              onClick={() => setIntent(UserIntent.FIND_SOLUTIONS)}
            >
              I want to <b>find</b> solutions or experts
            </Radio>
            <Radio
              selected={intent === UserIntent.PROVIDE_SOLUTIONS}
              onClick={() => setIntent(UserIntent.PROVIDE_SOLUTIONS)}
            >
              I want to <b>provide</b> solutions or expertise
            </Radio>
            <Radio
              selected={intent === UserIntent.CONNECT}
              onClick={() => setIntent(UserIntent.CONNECT)}
            >
              I want to <b>connect</b> my faculty with industry partners
            </Radio>
          </RadioContainer>
          {Boolean(intent) && <RadioHeading>Where do you work?</RadioHeading>}
          {intent === UserIntent.FIND_SOLUTIONS && (
            <RadioContainer>
              <Radio
                selected={detailedType === UserDetailedType.COMPANY_SPONSOR}
                onClick={() => setDetailedType(UserDetailedType.COMPANY_SPONSOR)}
              >
                I work in corporate R&amp;D
              </Radio>
              <Radio
                selected={detailedType === UserDetailedType.VENTURE_CAPITAL_SPONSOR}
                onClick={() => setDetailedType(UserDetailedType.VENTURE_CAPITAL_SPONSOR)}
              >
                I work for a venture capital firm
              </Radio>
              <Radio
                selected={detailedType === UserDetailedType.GOVERNMENT_SPONSOR}
                onClick={() => setDetailedType(UserDetailedType.GOVERNMENT_SPONSOR)}
              >
                I work for a government organization
              </Radio>
            </RadioContainer>
          )}
          {intent === UserIntent.PROVIDE_SOLUTIONS && (
            <RadioContainer>
              <Radio
                selected={detailedType === UserDetailedType.UNIVERSITY_SCIENTIST}
                onClick={() => setDetailedType(UserDetailedType.UNIVERSITY_SCIENTIST)}
              >
                I work as a researcher at a university
              </Radio>
              <Radio
                selected={detailedType === UserDetailedType.STARTUP_SCIENTIST}
                onClick={() => setDetailedType(UserDetailedType.STARTUP_SCIENTIST)}
              >
                I work for a startup
              </Radio>
              <Radio
                selected={detailedType === UserDetailedType.SUPPLIER}
                onClick={() => setDetailedType(UserDetailedType.SUPPLIER)}
              >
                I work for a supplier
              </Radio>
              <Radio
                selected={detailedType === UserDetailedType.CONSULTANT}
                onClick={() => setDetailedType(UserDetailedType.CONSULTANT)}
              >
                I work for a consultancy
              </Radio>
            </RadioContainer>
          )}
          {intent === UserIntent.CONNECT && (
            <RadioContainer>
              <Radio
                selected={detailedType === UserDetailedType.UNIVERSITY_ADMINISTRATOR}
                onClick={() => setDetailedType(UserDetailedType.UNIVERSITY_ADMINISTRATOR)}
              >
                I work as an admin at a university
              </Radio>
            </RadioContainer>
          )}
        </>
      ) : (
        <>
          <RadioButton
            testid="company-radio-button"
            text="Company"
            description="e.g. Bayer, PepsiCo, startups"
            value="company"
            selected={work}
            onChange={(v) => setWork(v)}
          />
          <RadioButton
            testid="ri-radio-button"
            text="Research Institution"
            description="e.g. universities, research center"
            value="research-institution"
            selected={work}
            onChange={(v) => setWork(v)}
          />
          <S.Padding height={25} />
          {work === "research-institution" && (
            <>
              <S.StepTitle>Are you a researcher or an administrator?</S.StepTitle>
              <RadioButton
                testid="ri-scientist-radio-button"
                text="Researcher"
                description="I conduct research."
                value="research-scientist"
                selected={role}
                onChange={(v) => setRole(v)}
              />
              <RadioButton
                testid="ri-admin-radio-button"
                text="Administrator"
                description="I connect faculty with research opportunities."
                value="research-administrator"
                selected={role}
                onChange={(v) => setRole(v)}
              />
            </>
          )}
          {work === "company" && (
            <>
              <S.StepTitle>What would you like to do on Halo?</S.StepTitle>
              <RadioButton
                testid="company-sponsor-radio-button"
                text="Find solutions"
                description="Post RFPs to our global network of scientists and startups"
                value="company-sponsor"
                selected={role}
                onChange={(v) => setRole(v)}
              />
              <RadioButton
                testid="company-scientist-radio-button"
                text="Submit proposals"
                description="Respond to RFPs to receive funding, expertise and other benefits"
                value="company-scientist"
                selected={role}
                onChange={(v) => setRole(v)}
              />
            </>
          )}
        </>
      )}
      <ButtonContainer work={work}>
        {FEATURE_FLAGS.SIGN_OUT_ON_ONBOARDING && (
          <Button
            type="button"
            onClick={async () => {
              await signOut();
            }}
          >
            Sign Out
          </Button>
        )}
        <Button
          type="button"
          data-testid="step2-submit-button"
          disabled={!role}
          onClick={onContinue}
          width="120px"
        >
          Continue
        </Button>
      </ButtonContainer>
    </S.StepContainer>
  );
}

export default Step2;

const ButtonContainer = styled.div`
  margin-top: ${({ work }) => (work ? "40px" : "0px")};
`;

const RadioHeading = styled.h2`
  margin: 0 0 16px;
  ${FONTS.SEMIBOLD_1}
`;

const RadioContainer = styled.div`
  display: flex;
  gap: 32px;
  flex-wrap: wrap;
  margin-bottom: 56px;
`;

const Radio = styled.div`
  flex-shrink: 0;
  width: 280px;
  padding: 16px;
  border: 1px solid ${COLORS.NEUTRAL_250};
  border-radius: 6px;
  cursor: pointer;
  ${FONTS.REGULAR_1}

  ${({ selected }) =>
    selected &&
    `
    border: 1px solid ${COLORS.HALO_BLUE};
    background: ${COLORS.BLUE_LIGHT_300};
    color: ${COLORS.BLUE_650};
  `}
`;
