import { useState } from "react";
import styled from "styled-components";

import toast from "@components/Toast";
import { Button, EmailDropdown } from "@components/library";
import BlankModal, { BlankModalProps } from "@components/library/ModalBases/BlankModal";
import { COLORS, FONTS } from "@constants";
import { inviteScientistsToCompanyNetwork } from "@requests/companyNetworkScientists";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import { track } from "@utils/appUtils";
import { getOptionFromValue } from "@utils/dropdownUtils";

const InviteNetworkScientistModal = ({ isOpen, onClose }: BlankModalProps) => {
  const [emails, setEmails] = useState<string[]>([]);
  const [emailError, setEmailError] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionError, setSubmissionError] = useState("");

  const onSubmit = async () => {
    setIsSubmitting(true);
    setSubmissionError("");
    try {
      track(SegmentEventName.Invite, {
        emails,
        ui_component: "InviteNetworkScientistModal",
        message,
        invitees: "scientists",
      });

      const { immediately_added, sent_invitation, already_in_network } =
        await inviteScientistsToCompanyNetwork(emails, message);

      const toastCustomizedMessage: string[] = [];
      if (immediately_added) toastCustomizedMessage.push(`${immediately_added} added`);
      if (sent_invitation) toastCustomizedMessage.push(`${sent_invitation} invited`);
      if (already_in_network)
        toastCustomizedMessage.push(`${already_in_network} already in your network`);

      const toastMessage = `You've expanded your network! ${toastCustomizedMessage.join(", ")}`;
      toast.success(toastMessage);

      onClose();
    } catch (err) {
      if (err.response) {
        setSubmissionError(err.response.data);
      } else {
        setSubmissionError("Something went wrong. Please try again.");
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <BlankModal
      isOpen={isOpen}
      onClose={onClose}
      closeOnDimmerClick={false}
      width="540px"
      headerText="Invite to your network"
    >
      <Content data-testid="scientist-network-invite-modal">
        <div>
          <EmailDropdown
            options={[]}
            value={emails.map(getOptionFromValue)}
            onChange={setEmails}
            setError={setEmailError}
            placeholder="Invite by email"
            formatCreateLabel={(input: string) => `Invite '${input}' to your network`}
          />
          {emailError.length !== 0 && <Error>{emailError}</Error>}
        </div>
        <TextArea
          placeholder="Add a message to your invite"
          rows="4"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Footer>
          <Error>{submissionError}</Error>
          <Button
            type="button"
            size="md"
            disabled={isSubmitting || emailError.length !== 0 || emails.length === 0}
            onClick={onSubmit}
          >
            Send Invite
          </Button>
        </Footer>
      </Content>
    </BlankModal>
  );
};

export default InviteNetworkScientistModal;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 20px;
`;

const Error = styled.div`
  ${FONTS.REGULAR_2}
  color: ${COLORS.RED};
  margin-top: 5px;
`;

const TextArea = styled.textarea`
  ${FONTS.REGULAR_2}
  width: 100%;
  margin: auto;
  padding: 10px;
  border: 1px solid ${COLORS.NEUTRAL_300};
  border-radius: 6px;
  resize: vertical;
  &::placeholder {
    ${FONTS.REGULAR_2}
    color: ${COLORS.NEUTRAL_500};
    opacity: 1;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
`;
