import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import toast from "@components/Toast";
import { Button } from "@components/library";
import KeywordsDropdown from "@components/library/Dropdowns/KeywordsDropdown/KeywordsDropdown";
import BlankModal, { BlankModalProps } from "@components/library/ModalBases/BlankModal";
import { COLORS, FONTS } from "@constants";
import { setCurrentUser } from "@redux/actions/profileActions";
import { RootState } from "@redux/store";
import { skipUpdateUserKeywords, updateUserKeywords } from "@requests/users";

const TaxonomyUpdateModal = ({ isOpen, onClose }: BlankModalProps) => {
  if (!isOpen) return null;

  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);
  const dispatch = useDispatch();

  const [disciplines, setDisciplines] = useState<string[]>(currentUser.disciplines);
  const [areasOfExpertise, setAreasOfExpertise] = useState<string[]>(
    currentUser.areas_of_expertise
  );
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSkip = () => {
    try {
      skipUpdateUserKeywords(currentUser.id);
    } catch (err) {
      console.error(err);
    }
    onClose();
    toast.success(
      <p>
        You can always update your expertise in{" "}
        <Link to="/account" style={{ textDecoration: "underline", color: "white" }}>
          Account Settings
        </Link>
        .
      </p>
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await updateUserKeywords(currentUser.id, {
        disciplines,
        areasOfExpertise,
      });
      if (response.data.success) {
        dispatch(
          setCurrentUser({
            ...currentUser,
            disciplines,
            areas_of_expertise: areasOfExpertise,
            has_updated_taxonomy: true,
          })
        );
        onClose();
      }
    } catch (err) {
      console.error(err);
    }
    setIsSubmitting(false);
  };

  const header =
    currentUser.role === 0
      ? `${currentUser.first_name}, it's time for a keyword checkup`
      : `${currentUser.first_name}, what is your expertise?`;
  const title =
    currentUser.role === 0
      ? "We made some changes to our taxonomy"
      : `We want to help R&D teams collaborate, and we made some changes to our taxonomy`;
  const text =
    currentUser.role === 0
      ? "Please take a second and update your keywords so we can better recommend opportunities that will interest you."
      : `Please take a second and add or update relevant keywords to your profile so R&D teams across ${
          currentUser.profile_info?.company_name ?? "your company"
        } can find you.`;

  return (
    <BlankModal
      width="600px"
      isOpen={isOpen}
      onClose={onClose}
      hideCloseButton={true}
      closeOnDimmerClick={false}
      disableBackgroundScroll={true}
      headerText={header}
    >
      <form onSubmit={handleSubmit}>
        <Content>
          <Title>{title}</Title>
          <Text>{text}</Text>
          {currentUser.role === 0 && (
            <>
              <Label>Disciplines</Label>
              <KeywordsDropdown
                type="disciplines"
                value={disciplines}
                onChange={setDisciplines}
                maxValues={3}
                placeholder="Select"
                isPortal
                showSuggestions
              />
              <Sublabel>
                E.g. Biomedical engineering
                <FloatRight>Select up to 3</FloatRight>
              </Sublabel>
            </>
          )}
          <Label>Areas of expertise</Label>
          <KeywordsDropdown
            type="areas_of_expertise"
            value={areasOfExpertise}
            onChange={setAreasOfExpertise}
            maxValues={5}
            placeholder="Select"
            isPortal
            showSuggestions
          />
          <Sublabel>
            E.g. Diagnostics, Neurodegeneration
            <FloatRight>Select up to 5</FloatRight>
          </Sublabel>
        </Content>
        <Footer>
          <div>{currentUser.role === 1 && <SkipLink onClick={onSkip}>Skip for now</SkipLink>}</div>
          <Button
            size="md"
            disabled={
              isSubmitting ||
              (currentUser.role === 0 && (!disciplines.length || !areasOfExpertise.length)) ||
              (currentUser.role === 1 && !areasOfExpertise.length)
            }
          >
            Update
          </Button>
        </Footer>
      </form>
    </BlankModal>
  );
};

export default TaxonomyUpdateModal;

const Content = styled.div`
  padding: 20px;
`;

const Footer = styled.div`
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${COLORS.NEUTRAL_300};
  margin-top: -32px;
`;

const Title = styled.div`
  ${FONTS.SEMIBOLD_1}
`;

const Text = styled.div`
  ${FONTS.REGULAR_1}
  margin-bottom: 40px;
`;

const Label = styled.div`
  ${FONTS.MEDIUM_2}
  margin-bottom: 8px;
`;

const Sublabel = styled.div`
  ${FONTS.REGULAR_3}
  width: 100%;
  text-align: left;
  margin-top: 8px;
  margin-bottom: 32px;
`;

const FloatRight = styled.span`
  float: right;
`;

const SkipLink = styled.a`
  ${FONTS.REGULAR_2}
  color: ${COLORS.NEUTRAL_500};
  text-decoration: underline;
  margin-top: -0.5em;

  &:hover {
    color: ${COLORS.BLACK};
    cursor: pointer;
  }
`;
