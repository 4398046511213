import { useEffect } from "react";

import { SingleSelectDropdown, TextInput } from "@components/library";
import KeywordsDropdown from "@components/library/Dropdowns/KeywordsDropdown/KeywordsDropdown";
import { SponsorRole } from "@tsTypes/sponsorProfiles";
import { getOptionFromValue, getValueFromOption } from "@utils/dropdownUtils";
import { isValidURL } from "@utils/textUtils";
import api from "src/requests/request";
import { useCompanyOptions } from "../Hooks";
import * as S from "../StepStyles";
import { COMPANY_SPONSOR_TITLE_LIMIT } from "./Step3";

const fetchCompanySuggestions = async (email) => {
  const responseJson = await api.get("/onboarding/company_suggestions", { params: { email } });
  return responseJson.data?.company_suggestions || [];
};

const roleDropdownOptions = Object.values(SponsorRole).map((text) => ({
  label: text,
  value: text,
}));

function CompanySponsor({ profileInfo, setProfileInfo, email, prefillCompanyName = "" }) {
  const { role, areasOfExpertise } = profileInfo;

  const [companyOptions] = useCompanyOptions();

  useEffect(() => {
    // Returns array of suggestions. Current UI only calls on initial load.
    // set company based on first suggestion
    (async () => {
      const fetchedSuggestions = await fetchCompanySuggestions(email);
      if (fetchedSuggestions.length) {
        const suggestedCompany = fetchedSuggestions[0];
        setProfileInfo({
          ...profileInfo,
          company: suggestedCompany,
        });
      }
    })();
  }, []);

  const handleCompany = (e, selection) => {
    if (selection?.action === "select-option" || selection?.action === "create-option") {
      setProfileInfo({
        ...profileInfo,
        company: e.value,
      });
    }
  };

  const handleCompanyWebsite = (e) => {
    setProfileInfo((oldProfileInfo) => ({
      ...oldProfileInfo,
      companyURL: e.target.value,
    }));
  };

  return (
    <>
      <S.DropdownRow>
        <S.DropdownTitle>Organization name</S.DropdownTitle>
        {prefillCompanyName.length > 0 ? (
          <S.Input type="text" value={prefillCompanyName} tabIndex={-1} fixed readOnly />
        ) : (
          <SingleSelectDropdown
            withSearchIcon
            placeholder="Search organization names or add new"
            isCreatable
            options={companyOptions}
            onChange={handleCompany}
            value={getOptionFromValue(profileInfo.company)}
            data-testid="company-sponsor-step-4-company-dropdown"
          />
        )}
      </S.DropdownRow>
      {profileInfo.company &&
        !companyOptions.map(({ value }) => value).includes(profileInfo.company) && (
          <S.DropdownRow>
            <S.DropdownTitle>Organization website</S.DropdownTitle>
            <TextInput
              placeholder="Enter organization website URL"
              onChange={handleCompanyWebsite}
              value={profileInfo?.companyURL || ""}
              data-testid="company-sponsor-company-website-input"
              errors={[
                {
                  hasError:
                    Boolean(profileInfo?.companyURL?.length) &&
                    !isValidURL(profileInfo?.companyURL),
                  errorMessage: "Please enter a valid URL",
                },
              ]}
            />
          </S.DropdownRow>
        )}
      <S.DropdownRow>
        <S.DropdownTitle>Which of the following best describes your role?</S.DropdownTitle>
        <SingleSelectDropdown
          placeholder="Select your role"
          options={roleDropdownOptions}
          onChange={(e) =>
            setProfileInfo((oldProfileInfo) => ({
              ...oldProfileInfo,
              role: getValueFromOption(e),
            }))
          }
          value={getOptionFromValue(role)}
          data-testid="ri-scientist-role-input"
        />
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>What is your job title?</S.DropdownTitle>
        <TextInput
          value={profileInfo.title || ""}
          placeholder="Enter your job title"
          onChange={(e) => {
            setProfileInfo({
              ...profileInfo,
              title: e.target.value,
            });
          }}
          errors={[
            {
              hasError: profileInfo.title?.length > COMPANY_SPONSOR_TITLE_LIMIT,
              errorMessage: `Job title must be less than ${COMPANY_SPONSOR_TITLE_LIMIT} characters`,
            },
          ]}
          hideClearButton
          data-testid="company-sponsor-step-4-title-field"
        />
      </S.DropdownRow>
      <S.DropdownRow>
        <S.DropdownTitle>What is your expertise?</S.DropdownTitle>
        <S.DropdownSubtitle>Optional. You can choose up to 10 terms.</S.DropdownSubtitle>
        <KeywordsDropdown
          type="areas_of_expertise"
          value={areasOfExpertise ?? []}
          onChange={(selection) => setProfileInfo({ ...profileInfo, areasOfExpertise: selection })}
          maxValues={10}
          placeholder="Search areas of expertise"
          showSuggestions
        />
      </S.DropdownRow>
    </>
  );
}

export default CompanySponsor;
