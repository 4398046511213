// Also update in app/models/role.rb

export enum RoleName {
  // resource: Company
  COMPANY_MEMBER = "company_member",
  COMPANY_ADMIN = "company_admin",
  COMPANY_SUPER_ADMIN = "company_super_admin",

  // resource: Request
  REQUEST_DRAFT_EDITOR = "request_draft_editor",
  REQUEST_COMMENTER = "request_commenter",
  REQUEST_LEAD = "request_lead",
  REQUEST_EXPERT = "request_expert",
}

export const PRETTY_ROLE_NAMES: { [key in RoleName]: string } = {
  [RoleName.COMPANY_MEMBER]: "Member",
  [RoleName.COMPANY_ADMIN]: "Admin",
  [RoleName.COMPANY_SUPER_ADMIN]: "Super admin",
  [RoleName.REQUEST_DRAFT_EDITOR]: "Draft editor",
  [RoleName.REQUEST_COMMENTER]: "Commenter",
  [RoleName.REQUEST_LEAD]: "Lead",
  [RoleName.REQUEST_EXPERT]: "Expert",
};

export type RoleResourceType = "Company" | "Request";
