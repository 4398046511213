import { Icon, ProfilePicture } from "@components/library";
import { COLORS, FONTS, WINDOW_DIMENSIONS } from "@constants";
import { RootState } from "@redux/store";
import { CurrentUser } from "@tsTypes/index";
import { CompanyBranding } from "@tsTypes/schema";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Popup } from "semantic-ui-react";
import styled from "styled-components";

interface Props {
  branding: CompanyBranding | null;
}

const NavbarPopup = ({ branding }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser as CurrentUser);

  if (!currentUser.id) return null;

  return (
    <StyledPopup
      trigger={
        <div>
          <Wide>
            <ProfilePicture size="sm" user={currentUser} />
            <Icon
              size="sm"
              name="Chevron Down"
              color={branding?.navbar_text_color ?? COLORS.WHITE}
            />
          </Wide>
          <Narrow>
            <Icon size="sm" name="Burger" color={branding?.navbar_text_color ?? COLORS.WHITE} />
          </Narrow>
        </div>
      }
      on="click"
      basic
      hideOnScroll
      hoverable
      position="bottom right"
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      content={
        <Content>
          <Profile>
            <ProfilePicture size="lg" user={currentUser} />
            <ProfileText>
              <Name>{currentUser.name}</Name>
              <Title>
                {currentUser.profile_info?.company_name ?? currentUser.profile_info?.title}
              </Title>
            </ProfileText>
          </Profile>
          <a href="/">
            <PopupButton>Visit Halo</PopupButton>
          </a>
          <a href="/sign_out">
            <PopupButton>Sign out</PopupButton>
          </a>
        </Content>
      }
    />
  );
};

export default NavbarPopup;

const StyledPopup = styled(Popup)`
  &&&& {
    margin-top: 15px;
    margin-bottom: 0px;
    padding: 0;
    border: none;
    ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
      box-shadow: 28px 15px 24px rgba(0, 0, 0, 0.15);
    }
  }
`;

const Wide = styled.div`
  display: flex;
  align-items: center;
  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    display: none;
  }
`;

const Narrow = styled.div`
  display: none;
  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    display: flex;
    align-items: center;
  }
`;

const Content = styled.div`
  min-width: 300px;
`;

const Profile = styled.div`
  display: flex;
  margin: 8px 20px;
  padding-top: 20px;
  gap: 20px;
  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    display: none;
  }
`;

const ProfileText = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Name = styled.div`
  ${FONTS.MEDIUM_1}
`;

const Title = styled.div`
  ${FONTS.MEDIUM_3}
`;

const PopupButton = styled(Button)`
  &&&& {
    ${FONTS.MEDIUM_2}
    padding: 15px 20px;
    background-color: ${COLORS.WHITE};
    border-bottom: 1px solid ${COLORS.NEUTRAL_200};
    color: ${COLORS.NEUTRAL_500};
    width: 300px;
    border-radius: 0px;
    margin: 0;
    text-align: left;
    ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
      ${FONTS.REGULAR_2}
      width: 100%;
      color: ${COLORS.BLACK};
    }
    &:hover {
      background-color: ${COLORS.NEUTRAL_50};
    }
  }
`;
