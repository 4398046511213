import {
  Checkbox,
  MultiSelectDropdown,
  SingleSelectDropdown,
  UniversityDropdown,
} from "@components/library";
import { COUNTRY_OPTIONS, FEATURE_FLAGS } from "@constants";
import { getDisciplines } from "@requests/keywords";
import { useQuery } from "@tanstack/react-query";
import { getOptionFromValue, getValueFromOption } from "@utils/dropdownUtils";
import { useEffect } from "react";
import api from "src/requests/request";
import * as S from "../StepStyles";

const expertiseOptions = [
  "Healthcare and Life Sciences",
  "Engineering and Physical Sciences",
  "New Venture Creation",
  "Data Science",
  "Agriculture",
].map((text) => ({
  label: text,
  value: text,
}));

function ResearchInstitutionAdmin({ profileInfo, setProfileInfo, prefillInstitutionName = "" }) {
  const { data: disciplineOptions } = useQuery({
    queryKey: ["getDisciplines"],
    queryFn: () => getDisciplines(),
    select: (data) => data.map(getOptionFromValue),
  });

  useEffect(() => {
    if (!disciplineOptions) return;
    if ("disciplines" in profileInfo) return;
    setProfileInfo({
      ...profileInfo,
      disciplines: disciplineOptions?.map(getValueFromOption),
    });
  }, [disciplineOptions]);

  const { university, universityHasCountry, universityExpertise, countryOption, disciplines } =
    profileInfo;

  const handleDisciplines = (value) => {
    setProfileInfo({
      ...profileInfo,
      disciplines: disciplines.includes(value)
        ? disciplines.filter((v) => v !== value)
        : [...disciplines, value],
    });
  };

  const handleSelectUniversityOption = async (option) => {
    if (!option) return;

    const responseJson = await api.get("/universities/has_country", {
      params: { name: getValueFromOption(option) },
    });

    const { has_country } = responseJson.data;

    setProfileInfo({
      ...profileInfo,
      university: getValueFromOption(option),
      universityHasCountry: has_country,
    });
  };

  const handleAllDisciplines = (event) => {
    if (event.target.checked) {
      setProfileInfo({
        ...profileInfo,
        disciplines: disciplineOptions?.map((option) => option?.value),
      });
    } else {
      setProfileInfo({
        ...profileInfo,
        disciplines: [],
      });
    }
  };

  return (
    <>
      <S.DropdownRow>
        <S.DropdownTitle>Research institution</S.DropdownTitle>
        {prefillInstitutionName.length > 0 ? (
          <S.Input type="text" value={prefillInstitutionName} tabIndex={-1} fixed readOnly />
        ) : (
          <>
            <UniversityDropdown
              value={getOptionFromValue(university)}
              onChange={handleSelectUniversityOption}
              data-testid="ri-admin-institution-name-input"
            />
            <S.ContactLink>
              You can{" "}
              <a
                href="/contact?message=Hello, I can't find my institution as an option. My institution is: "
                target="_blank"
              >
                request that we add your institution
              </a>{" "}
              if you don&rsquo;t see it above.
            </S.ContactLink>
          </>
        )}
      </S.DropdownRow>
      {!universityHasCountry && prefillInstitutionName.length === 0 && (
        <S.DropdownRow>
          <S.DropdownTitle>Institution location</S.DropdownTitle>
          <SingleSelectDropdown
            placeholder="Search countries"
            value={countryOption}
            options={COUNTRY_OPTIONS}
            onChange={(option) =>
              setProfileInfo((oldProfileInfo) => ({
                ...oldProfileInfo,
                countryOption: option,
                country_code: getValueFromOption(option),
              }))
            }
            data-testid="ri-admin-institution-location-input"
          />
        </S.DropdownRow>
      )}
      {FEATURE_FLAGS.UNIVERSITY_ADMIN_DISCIPLINES ? (
        <S.DropdownRow>
          <S.DropdownTitle>Which topics would you like to hear about on Halo?</S.DropdownTitle>
          <S.CheckboxContainer>
            <S.CheckboxItem>
              <Checkbox
                label="All disciplines"
                isChecked={disciplines?.length === disciplineOptions?.length}
                onChange={handleAllDisciplines}
                size="sm"
              />
            </S.CheckboxItem>
            {disciplineOptions?.map((option) => (
              <S.CheckboxItem key={option?.value}>
                <Checkbox
                  label={option?.label}
                  isChecked={disciplines?.includes(option?.value)}
                  onChange={() => handleDisciplines(option?.value)}
                  size="sm"
                />
              </S.CheckboxItem>
            ))}
          </S.CheckboxContainer>
        </S.DropdownRow>
      ) : (
        <S.DropdownRow>
          <S.DropdownTitle>
            Where do you focus your efforts on behalf of your faculty?
          </S.DropdownTitle>
          <MultiSelectDropdown
            placeholder="Select your focus"
            value={universityExpertise?.map(getOptionFromValue) ?? []}
            options={expertiseOptions}
            onChange={(options) =>
              setProfileInfo({
                ...profileInfo,
                universityExpertise: options.map(getValueFromOption),
              })
            }
            maxValues={3}
            data-testid="ri-admin-research-areas-input"
            isClearable={false}
          />
        </S.DropdownRow>
      )}
    </>
  );
}

export default ResearchInstitutionAdmin;
