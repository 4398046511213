import { TextButton } from "@components/library";
import { WINDOW_DIMENSIONS } from "@constants";
import { RootState } from "@redux/store";
import { company_scientist_network_path, reviewer_dashboard_path } from "@routes/routes";
import { marketplacePath } from "@routes/utils";
import { getPrevUrlCookie } from "@utils/authUtils";
import { useSelector } from "react-redux";
import styled from "styled-components";
import * as S from "../StepStyles";
import Step4NextStep from "./Step4NextStep";

const Step4 = ({ userType }) => {
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);
  const subscription = useSelector((state: RootState) => state.company.subscription);

  const routeToPrevUrl = () => {
    getPrevUrlCookie(marketplacePath("requests", { follow: "true" }), false, 0);
  };

  const hasPreviousAction = Boolean(getPrevUrlCookie("", false, currentUser.role ?? 0, false));

  return (
    <S.StepContainer
      width={["research-scientist", "company-scientist"].includes(userType) ? "800px" : "592px"}
    >
      <S.StepHeader
        subheading="Explore Halo"
        heading={
          <>
            Welcome, {currentUser.first_name}!
            <br />
            Here&apos;s what you can do next on Halo.
          </>
        }
      />
      <NextStepsContainer>
        {(() => {
          switch (userType) {
            case "research-scientist":
            case "company-scientist":
              return (
                <>
                  <Step4NextStep
                    iconName="Profile"
                    title="Refine your profile and get discovered"
                    description="Add a summary of your work, publications, patents, and more."
                    to={`/profile/${currentUser.first_name}-${currentUser.last_name}-${currentUser.id}`}
                    width="246px"
                  />
                  <Step4NextStep
                    iconName="Search"
                    title="Find opportunities on the marketplace"
                    description="Find opportunities to offer your expertise or apply to R&D requests."
                    to={marketplacePath("requests")}
                    width="246px"
                  />
                  <Step4NextStep
                    iconName="Organization"
                    title="Join company networks"
                    description="Discover and connect with R&D teams actively looking to engage with experts."
                    to={marketplacePath("partners")}
                    width="246px"
                  />
                </>
              );
            case "company-sponsor":
              return (
                <>
                  <Step4NextStep
                    iconName="RFP"
                    title="Draft your first partnering request"
                    description="Create a draft of your request and submit it for publishing."
                    to={reviewer_dashboard_path({ create: true })}
                    width="280px"
                  />
                  {subscription?.features.company_scientist_network &&
                    (subscription.features.global_network_search ? (
                      <Step4NextStep
                        iconName="Search"
                        title="Search for partners on Halo's network"
                        description="Search our network of experts actively seeking to partner."
                        to={company_scientist_network_path()}
                        width="280px"
                      />
                    ) : (
                      <Step4NextStep
                        iconName="Profile"
                        title="Help build your organization's network"
                        description="Use Halo to centralize your network of existing partners for your team."
                        to={company_scientist_network_path()}
                        width="280px"
                      />
                    ))}
                </>
              );
            case "research-administrator":
              return (
                <>
                  <Step4NextStep
                    iconName="Profile"
                    title="Invite faculty members to join Halo"
                    description="Introduce researchers to Halo to help them find relevant opportunities."
                    to="/"
                    width="280px"
                  />
                  <Step4NextStep
                    iconName="Search"
                    title="Find opportunities on the marketplace"
                    description="Find opportunities to share with faculty members."
                    to={marketplacePath("requests")}
                    width="280px"
                  />
                </>
              );
          }
        })()}
      </NextStepsContainer>
      {(hasPreviousAction || userType === "company-sponsor") && (
        <TextButton
          onClick={routeToPrevUrl}
          text="...or continue where you left off"
          size="md"
          iconName="Arrow Right"
          margin="48px 0 0"
        />
      )}
    </S.StepContainer>
  );
};

export default Step4;

const NextStepsContainer = styled.div`
  display: flex;
  gap: 32px;

  ${WINDOW_DIMENSIONS.TABLET_MEDIA_QUERY} {
    flex-direction: column;
  }
`;
