import { COLORS, FONTS } from "@constants";
import styled from "styled-components";

export const RadioContainer = styled.button`
  position: relative;
  display: flex;
  flex: 0;
  ${({ compact }) => (compact ? "width: 150px;" : "")}
  box-sizing: border-box;
  margin-bottom: ${(props) => (props.compact ? "8px" : "15px")};
  cursor: pointer;
  user-select: none;
  background-color: ${(props) => (props.selected ? COLORS.WHITE : `initial`)};
  border: ${(props) =>
    props.selected ? `1px solid ${COLORS.HALO_BLUE}` : `1px solid ${COLORS.NEUTRAL_400}`};
  border-radius: 2px;
  :hover {
    background-color: ${COLORS.WHITE};
  }
  transition: background-color 0.1s linear;
  width: 100%;
`;

export const RadioOuterCircle = styled.div`
  position: absolute;
  top: 16px;
  left: 10px;
  width: 16px;
  height: 16px;
  background-color: ${COLORS.WHITE};
  border: 1px solid ${({ selected }) => (selected ? COLORS.HALO_BLUE : COLORS.BLACK)};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  transition: all 0.1s linear;
`;

export const RadioInnerCircle = styled.div`
  width: ${(props) => (props.selected ? `6px` : `0px`)};
  height: ${(props) => (props.selected ? `6px` : `0px`)};
  border-radius: 50%;
  background-color: ${COLORS.HALO_BLUE};
  transition: all 0.1s linear;
`;

export const TextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding-left: 32px;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 12px;
`;

export const TitleText = styled.div`
  width: 100%;
  color: ${COLORS.BLACK};
  ${(props) => (props.compact ? FONTS.REGULAR_2 : FONTS.MEDIUM_2)};
  text-align: left;
`;

export const DescriptionText = styled.div`
  display: ${(props) => (props.compact ? "none" : "inherit")};
  width: 100%;
  color: ${COLORS.NEUTRAL_500};
  padding-top: 5px;
  ${FONTS.REGULAR_2};
  text-align: left;
`;
