import toast from "@components/Toast";
import { Button } from "@components/library";
import EmailDropdown from "@components/library/Dropdowns/EmailDropdown";
import BlankModal, { BlankModalProps } from "@components/library/ModalBases/BlankModal";
import { COLORS, FONTS } from "@constants";
import { fetchAllCompanyMembers } from "@redux/actions/companyActions";
import { RootState } from "@redux/store";
import { generateEmailDropdownOptions, getOptionFromValue } from "@utils/dropdownUtils";
import { SyntheticEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { UserOption } from "../Dropdowns/UserDropdown";

export interface ShareWithinCompanyProps extends BlankModalProps {
  toastText: string;
  handleShare: (email: string, message: string) => Promise<any>; // throw on error
}

const ShareWithinCompanyModal = ({
  isOpen,
  onClose,
  headerText,
  toastText,
  handleShare,
}: ShareWithinCompanyProps) => {
  if (!isOpen) return null;

  const dispatch = useDispatch();
  const companyMembers = useSelector((state: RootState) => state.company.companyMembers);
  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);
  const dropdownOptions: UserOption[] = generateEmailDropdownOptions(companyMembers, [
    currentUser.email,
  ]);

  const [emails, setEmails] = useState<string[]>([]);
  const [emailError, setEmailError] = useState("");
  const [message, setMessage] = useState<string>("");
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchAllCompanyMembers(currentUser.profile_info?.company_id));
  }, []);

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    Promise.all(emails.map((email) => handleShare(email, message)))
      .then(() => {
        onClose();
        toast.success(toastText);
      })
      .catch((err) => console.error(err))
      .finally(() => setIsSubmitting(false));
  };

  return (
    <BlankModal
      isOpen={isOpen}
      onClose={onClose}
      closeOnDimmerClick={emails.length === 0 && message.length === 0}
      headerText={headerText}
      width="600px"
    >
      <Content data-testid="share-within-company-modal">
        <form onSubmit={handleSubmit}>
          <Label>Email Address(es)</Label>
          <EmailDropdown
            value={emails.map(getOptionFromValue)}
            options={dropdownOptions}
            onChange={setEmails}
            setError={setEmailError}
          />
          <Error>{emailError}</Error>
          <Label extraMarginTop>Message (Optional)</Label>
          <TextArea
            placeholder="Add a message"
            rows="4"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            data-testid="message-input"
          />
          <SubmitButtonContainer>
            <Button
              type="submit"
              size={"md"}
              iconName="Share"
              disabled={emails.length === 0 || Boolean(emailError) || isSubmitting}
              data-testid="submit-button"
            >
              Share
            </Button>
          </SubmitButtonContainer>
        </form>
      </Content>
    </BlankModal>
  );
};

export default ShareWithinCompanyModal;

const Content = styled.div`
  padding: 20px;
`;

const Label = styled.label`
  ${FONTS.SEMIBOLD_2}
  margin-bottom: 10px;
  ${(p) => p.extraMarginTop && "margin-top: 30px;"}
`;

const Error = styled.div`
  ${FONTS.REGULAR_2}
  color: ${COLORS.RED};
  margin-top: 5px;
`;

const TextArea = styled.textarea`
  ${FONTS.REGULAR_2}
  width: 100%;
  margin: auto;
  padding: 10px;
  border: 1px solid ${COLORS.NEUTRAL_300};
  border-radius: 6px;
  resize: none;

  &::placeholder {
    ${FONTS.REGULAR_2}
    color: ${COLORS.NEUTRAL_500};
  }
`;

const SubmitButtonContainer = styled.div`
  margin-top: 30px;
`;
