import toast from "@components/Toast";
import { Button, Icon, ProfilePicture, TextLink, Tooltip } from "@components/library";
import { COLORS, FONTS } from "@constants";
import { toggleLoginModal } from "@redux/actions/profileActions";
import { RootState } from "@redux/store";
import {
  deleteRequestComment,
  downvoteRequestComment,
  editRequestComment,
  upvoteRequestComment,
} from "@requests/requestComments";
import { SegmentEventName } from "@tsTypes/__generated__/enums";
import {
  RequestCommentPartial,
  RfpPageRequest,
  ScientistProfilePartial,
  SponsorProfilePartial,
  UniversityProfilePartial,
} from "@tsTypes/index";
import { RequestForStartupsShowResponse } from "@tsTypes/request_for_startups/show";
import { UserRole } from "@tsTypes/users";
import { track } from "@utils/appUtils";
import appsignal from "@utils/appsignal";
import { format } from "date-fns";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import RequestDiscussionEditForm from "./RequestDiscussionEditForm";
import RequestDiscussionSubmitForm from "./RequestDiscussionSubmitForm";
import line from "./line.svg";
import TOOLTIP_CONTENT from "./tooltipContent";

interface Props {
  comment: RequestCommentPartial;
  request: RfpPageRequest | RequestForStartupsShowResponse;
  commentTreeLevel?: number;
  refreshComments: () => Promise<void>;
  canAnswer: boolean;
  isCompanyHidden: boolean;
  canShowQA?: boolean;
}

const RequestComment = ({
  comment,
  request,
  commentTreeLevel = 0,
  refreshComments,
  canAnswer,
  isCompanyHidden,
  canShowQA = true,
}: Props) => {
  const {
    id,
    user,
    created_at,
    text,
    open,
    reply_to,
    replies,
    cached_votes_up,
    cached_votes_down,
    upvoted_by_current_user,
    downvoted_by_current_user,
  } = comment;

  const [isCommenting, setIsCommenting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const currentUser = useSelector((state: RootState) => state.profiles.currentUser);
  const dispatch = useDispatch();

  const isScientist = currentUser.role === UserRole.SCIENTIST;
  const isSponsor = currentUser.role === UserRole.SPONSOR;
  const isUniversityAdmin = currentUser.role === UserRole.UNIVERSITY_ADMIN;

  const commentBelongsToCurrentUser = currentUser.id && currentUser.id === user?.id;

  const isQuestion = commentTreeLevel === 0;
  const showEditActions =
    commentBelongsToCurrentUser && !isEditing && !(isQuestion && Number(replies?.length) > 0);
  const canUpvote = !isSponsor && !commentBelongsToCurrentUser;
  const showAnswerButton = !isCommenting && isQuestion && !replies?.length && canAnswer;
  const showReplyButton =
    !isCommenting &&
    !isQuestion &&
    !replies?.length &&
    open &&
    reply_to?.user_id === currentUser.id;

  const commenterName =
    isCompanyHidden && user?.role === UserRole.SPONSOR ? "Team Member" : user?.name;

  let commenterTitle: string | null = "";
  let commenterLocation: string | null = "";

  if (user?.role === UserRole.SCIENTIST) {
    commenterLocation = (user.profile_info as ScientistProfilePartial)?.location;
    commenterTitle = (user.profile_info as ScientistProfilePartial)?.title ?? "Scientist";
  } else if (user?.role === UserRole.UNIVERSITY_ADMIN) {
    commenterLocation = (user.profile_info as UniversityProfilePartial)?.university;
    commenterTitle = (user.profile_info as UniversityProfilePartial)?.role;
  } else if (isCompanyHidden) {
    commenterLocation = "Private Company";
    commenterTitle = "Reviewer";
  } else if ((user?.profile_info as SponsorProfilePartial)?.company_name) {
    commenterLocation = (user?.profile_info as SponsorProfilePartial).company_name;
    commenterTitle = (user?.profile_info as SponsorProfilePartial)?.title ?? "Reviewer";
  }

  const onUndo = async (requestCommentId) => {
    try {
      await deleteRequestComment(requestCommentId);

      await refreshComments();

      toast.success("Comment deleted!");
    } catch (e) {
      toast.error("There was an error deleting this comment. Please try again later.");

      appsignal.sendError(e, (span) => {
        span.setAction("RequestDiscussionSubmitForm#onUndo");
        span.setTags({
          commentId: String(requestCommentId),
          userId: currentUser.id,
          userEmail: currentUser.email,
        });
      });
    }
  };

  const onReplySuccess = async (requestCommentId) => {
    setIsEditing(false);
    setIsCommenting(false);
    await refreshComments();
    toast.success(
      <ToastContent>
        {"Response posted!"}
        <UndoButton onClick={() => onUndo(requestCommentId)}>Undo</UndoButton>
      </ToastContent>
    );
  };

  const onEditSuccess = async () => {
    setIsEditing(false);
    setIsCommenting(false);
    await refreshComments();
    toast.success(<ToastContent>Response updated!</ToastContent>);
  };

  const onUpvote = async () => {
    if (!currentUser.id) {
      dispatch(toggleLoginModal({ open: true }));
      return;
    }

    if (!canUpvote) return;

    try {
      await upvoteRequestComment(id);
      track(SegmentEventName.Click, {
        ui_component: "Upvote Comment",
        react_component: "RequestComment",
        request_slug: request.slug,
        is_undo: upvoted_by_current_user,
      });
      refreshComments();
    } catch (e) {
      toast.error("There was an error upvoting this comment. Please try again later.");

      appsignal.sendError(e, (span) => {
        span.setAction("RequestComment#onUpvote");
        span.setTags({
          commentId: String(id),
          userId: currentUser.id,
          userEmail: currentUser.email,
        });
      });
    }
  };

  const onDownvote = async () => {
    if (!currentUser.id) {
      dispatch(toggleLoginModal({ open: true }));
      return;
    }

    if (!isScientist && !isUniversityAdmin) return;

    try {
      await downvoteRequestComment(id);
      track(SegmentEventName.Click, {
        ui_component: "Downvote Comment",
        react_component: "RequestComment",
        request_slug: request.slug,
        is_undo: upvoted_by_current_user,
      });
      refreshComments();
    } catch (e) {
      toast.error("There was an error downvoting this comment. Please try again later.");

      appsignal.sendError(e, (span) => {
        span.setAction("RequestComment#onDownvote");
        span.setTags({
          commentId: String(id),
          userId: currentUser.id,
          userEmail: currentUser.email,
        });
      });
    }
  };

  const onDelete = async () => {
    try {
      await deleteRequestComment(id);
      toast.success("Comment deleted!");
      refreshComments();
    } catch (e) {
      toast.error("There was an error deleting this comment. Please try again later.");

      appsignal.sendError(e, (span) => {
        span.setAction("RequestComment#onDelete");
        span.setTags({
          commentId: String(id),
          userId: currentUser.id,
          userEmail: currentUser.email,
        });
      });
    }
  };

  const onCloseThread = async () => {
    try {
      await editRequestComment(id, undefined, false);
      track(SegmentEventName.Click, {
        ui_component: "Close Thread",
        react_component: "RequestComment",
        request_slug: request.slug,
      });
      toast.success(
        <ToastContent>
          {"Thread closed!"}
          <UndoButton onClick={() => editRequestComment(id, undefined, true)}>Undo</UndoButton>
        </ToastContent>
      );
      refreshComments();
    } catch (e) {
      toast.error("There was an error closing this thread. Please try again later.");

      appsignal.sendError(e, (span) => {
        span.setAction("RequestComment#onCloseThread");
        span.setTags({
          commentId: String(id),
          userId: currentUser.id,
          userEmail: currentUser.email,
        });
      });
    }
  };

  const showAwaitingResponse =
    (showAnswerButton || showReplyButton) && currentUser.role === UserRole.SPONSOR;

  return (
    <Container highlight={showAwaitingResponse} isQuestion={isQuestion}>
      {showAwaitingResponse && isQuestion && (
        <AwaitingReponse>Awaiting your response</AwaitingReponse>
      )}
      <Comment>
        <MainContent>
          {isEditing ? (
            <RequestDiscussionEditForm
              comment={comment}
              onSuccess={onEditSuccess}
              setIsEditing={setIsEditing}
            />
          ) : (
            <TextContainer isQuestion={isQuestion}>
              <QuestionAnswer highlight={showAnswerButton} canShowQA={canShowQA}>
                {isQuestion ? "Q." : "A."}
              </QuestionAnswer>
              <div>
                {showAwaitingResponse && !isQuestion && (
                  <AwaitingReponse>Awaiting your response</AwaitingReponse>
                )}
                <TextContent>{text}</TextContent>
              </div>
            </TextContainer>
          )}
          {showEditActions && (
            <MessageActions>
              {isSponsor && (
                <Button variant="secondary" size="xs" onClick={() => setIsEditing(true)}>
                  Edit
                </Button>
              )}
              <Delete onClick={onDelete}>Delete</Delete>
            </MessageActions>
          )}
          <User isQuestion={isQuestion} isEditing={isEditing}>
            <PictureContainer
              to={user?.role === UserRole.SCIENTIST ? `/profile/${user?.profile_id}` : undefined}
            >
              <ProfilePicture
                user={user!}
                size="sm"
                isUserHidden={user?.role === UserRole.SPONSOR && isCompanyHidden}
              />
            </PictureContainer>
            <UserInfo>
              <Name>
                {user?.role === UserRole.SCIENTIST ? (
                  <TextLink
                    to={`/profile/${user?.profile_id}`}
                    font={FONTS.REGULAR_3}
                    onClick={() =>
                      track(SegmentEventName.Click, {
                        ui_component: "Scientist Profile Link",
                        react_component: "RequestComment",
                        request_slug: request.slug,
                      })
                    }
                  >
                    {commenterName}, {commenterTitle}, {commenterLocation}
                  </TextLink>
                ) : (
                  <>
                    {commenterName}, {commenterTitle}, {commenterLocation}
                    <Icon name="Verified" color={COLORS.HALO_BLUE} size="xs" margin="0 0 0 2px" />
                  </>
                )}
              </Name>
              <DatePosted>{format(new Date(created_at), "MMMM d, yyyy")}</DatePosted>
            </UserInfo>
          </User>
        </MainContent>
        {user?.role !== UserRole.SPONSOR && (
          <Tooltip
            content={TOOLTIP_CONTENT.SCIENTIST_UPVOTE}
            position="right"
            tooltipWidth="max-content"
            arrowOffsetTop="15px"
          >
            <QuestionVoteContainer
              canVote={canUpvote}
              didVote={upvoted_by_current_user}
              onClick={onUpvote}
            >
              <Icon name="Chevron Up" size="md" color={COLORS.HALO_BLUE} />
              <QuestionVoteScore>{cached_votes_up}</QuestionVoteScore>
            </QuestionVoteContainer>
          </Tooltip>
        )}
      </Comment>
      {user?.role === UserRole.SPONSOR && (
        <Helpful>
          {currentUser.role === UserRole.SPONSOR
            ? "Researcher rating"
            : "Is this response helpful?"}
          <HelpfulThumb canVote={canUpvote} onClick={onUpvote}>
            <Icon
              name={`Thumb Up${upvoted_by_current_user ? " Filled" : ""}`}
              size="sm"
              color={canUpvote ? COLORS.HALO_BLUE : COLORS.NEUTRAL_400}
            />{" "}
            {cached_votes_up}
          </HelpfulThumb>
          <HelpfulThumb canVote={canUpvote} onClick={onDownvote}>
            <Icon
              name={`Thumb Down${downvoted_by_current_user ? " Filled" : ""}`}
              size="sm"
              color={canUpvote ? COLORS.HALO_BLUE : COLORS.NEUTRAL_400}
            />{" "}
            {cached_votes_down}
          </HelpfulThumb>
        </Helpful>
      )}
      {(showAnswerButton || showReplyButton) && (
        <>
          <Button
            variant="secondary"
            size="sm"
            onClick={() => setIsCommenting(true)}
            margin={showAnswerButton ? "32px 0 0 24px" : "32px 0 8px 24px"}
            width="min-content"
          >
            {isQuestion ? "Answer" : "Reply"}
          </Button>
          {open && reply_to && currentUser.role === UserRole.SPONSOR && (
            <Button
              size="sm"
              variant="ghost"
              onClick={onCloseThread}
              margin="0 0 0 24px"
              width="min-content"
            >
              Close thread
            </Button>
          )}
        </>
      )}
      {isCommenting && (
        <RequestDiscussionSubmitForm
          companyName={
            isCompanyHidden ? "Private Company" : (user?.profile_info as any).company_name
          }
          request={request}
          replyTo={comment}
          commentTreeLevel={commentTreeLevel}
          onSuccess={onReplySuccess}
          setIsCommenting={setIsCommenting}
        />
      )}
      {!isQuestion && Number(replies?.length) > 0 && <HorizontalLine />}
      <Answers indentAnswers={isQuestion && Number(replies?.length)}>
        {Number(replies?.length) > 0 && (
          <>
            {isQuestion && <Line src={line} />}
            {replies?.map((reply, index) => (
              <RequestComment
                key={reply.id}
                commentTreeLevel={commentTreeLevel + 1}
                canShowQA={isQuestion && index === 0}
                request={request}
                comment={reply}
                refreshComments={refreshComments}
                canAnswer={canAnswer}
                isCompanyHidden={isCompanyHidden}
              />
            ))}
          </>
        )}
      </Answers>
    </Container>
  );
};

export default RequestComment;

const Container = styled.div`
  ${({ highlight, isQuestion }) =>
    highlight &&
    `
      padding: 16px ${isQuestion ? "16px" : "0px"} 0px;
      border-radius: 8px;
      background: ${COLORS.ORANGE_50};
      margin-bottom: 56px;
    `}
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${COLORS.NEUTRAL_200};
  margin: 32px 0;
`;

const Comment = styled.div`
  display: flex;
  margin: 0 0 24px 0;
`;
const MainContent = styled.div`
  flex: 1;
`;
const TextContainer = styled.div`
  ${({ isQuestion }) => (isQuestion ? FONTS.SEMIBOLD_1 : FONTS.REGULAR_1)}
  display: flex;
  gap: 8px;
`;
const QuestionAnswer = styled.strong`
  background: ${({ highlight }) => (highlight ? COLORS.ORANGE_50 : COLORS.WHITE)};
  height: min-content;
  ${({ canShowQA }) => !canShowQA && "visibility: hidden;"}
`;
const TextContent = styled.div`
  word-break: break-word;
`;
const User = styled.div`
  display: inline-flex;
  gap: 8px;
  ${FONTS.REGULAR_3}
  margin-top: 16px;
  margin-left: ${({ isEditing }) => (isEditing ? "0" : "24px")};
`;
const PictureContainer = styled(Link)`
  flex-grow: 1;
  pointer-events: ${({ to }) => (to ? "auto" : "none")};
  text-decoration: none !important;
`;
const UserInfo = styled.div`
  text-overflow: ellipsis;
  flex-shrink: 1;
`;
const Name = styled.div`
  display: inline-flex;
  align-items: center;
  ${FONTS.REGULAR_3}
  line-height: 1.5;
`;
const DatePosted = styled.div`
  ${FONTS.REGULAR_3}
  color: ${COLORS.NEUTRAL_500};
`;
const Delete = styled.div`
  display: block;
  text-decoration: underline;
  cursor: pointer;
  ${FONTS.SEMIBOLD_2}
  color: ${COLORS.RED};
`;
const QuestionVoteContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 2px solid ${COLORS.NEUTRAL_100};
  border-radius: 12px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: ${COLORS.BLUE_LIGHT_700};
  height: 100%;
  margin-left: 24px;
  color: ${COLORS.NEUTRAL_500};
  ${({ didVote }) => didVote && `color: ${COLORS.HALO_BLUE};`}
  ${({ canVote }) =>
    canVote &&
    `
      cursor: pointer;
      &:hover {
        background: ${COLORS.BLUE_LIGHT_300};
        border: 2px solid ${COLORS.BLUE_LIGHT_300};
      }
    `}
`;
const QuestionVoteScore = styled.div`
  margin-top: -4px;
`;
const Answers = styled.div`
  position: relative;
  margin: 32px 0 0;
  ${({ indentAnswers }) =>
    indentAnswers &&
    `
    margin: 32px 0 56px;
    padding-left: 28px;
  `}
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const Line = styled.img`
  position: absolute;
  left: 5px;
  top: -108px;
  z-index: -1;
`;
const MessageActions = styled.div`
  margin: 16px 0 8px 24px;
  display: flex;
  align-items: center;
  gap: 16px;
`;
const Helpful = styled.div`
  margin: 16px 0 8px 24px;
  display: flex;
  align-items: center;
  gap: 16px;
  ${FONTS.REGULAR_3}
`;
const HelpfulThumb = styled.div`
  ${({ canVote }) => canVote && "cursor: pointer;"}
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ canVote }) => (canVote ? COLORS.HALO_BLUE : COLORS.NEUTRAL_400)};
`;
const AwaitingReponse = styled.div`
  ${FONTS.TAG_MEDIUM_2}
  color: ${COLORS.ORANGE_900};
  margin: 0 0 8px;
`;
const ToastContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const UndoButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  text-decoration: underline;
`;
